@charset "utf-8";

:root {
   --blau: #3E539E;
}

.form-control {
   border: 1px solid var(--blau);
}

.bold {
   font-weight: 700 !important;
}

@media (min-width: 1460px) {
   .container {
      width: 1430px;
   }
}

.border-blau {
   border: 2px solid var(--blau);
}

.border-blau-1 {
   border: 1px solid var(--blau);
   border-radius: 8px;
}

.border__bottom-blau {
   border-bottom: 2px solid var(--blau);
}

.carousel-control {
   width: 2%;
}

.input-group .form-control {
   z-index: 1;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
   html {
      font-size: 14px;
   }
   .xxx--page {
      padding-left: 60px;
   }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
   .xxx--page {
      padding-left: 60px;
   }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}