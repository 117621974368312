
/*
 *
 * Allgemein
 */

html {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
}

body {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}

a,
a *,
button,
button *,
.btn,
.btn *,
.cursor-pointer,
.cursor-pointer *,
*[href],
*[href] *,
*[onclick],
*[onclick] *,
*[ondblclick],
*[ondblclick] *,
*[ui-sref],
*[ui-sref] *,
*[ng-click],
*[ng-click] * {
    cursor: pointer !important;
}

img {
    max-width: 100%; 
    height: auto;
}

.btn.xxx---is-active,
.btn.xxx---is-active * {
    cursor: default !important;
}

.table>tbody>tr>td {
    border: none;
}

.table.table-bordered>tbody>tr>td {
    border: 1px solid #ddd;
}


/*
 *
 * Cookie Warning
 */

*[ng-if="showCookieWarn"] {
    /* sticky-top */
    /* padding-top: 220px; */
    /* sticky-bottom */
    padding-bottom: 220px;
}

@media (min-width: 420px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 140px; */
        /* sticky-bottom */
        padding-bottom: 140px;
    }
}

@media (min-width: 720px) {
    *[ng-if="showCookieWarn"] {
        /* sticky-top */
        /* padding-top: 60px; */
        /* sticky-bottom */
        padding-bottom: 60px;
    }
}

.cookie-warn--message {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: lighter;
}


/*
 *
 * Schrift und Links
 */

a,
p {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}

.svg-icon__menu {
    height: 13px;
    width: 13px;
    fill: #209bd7;
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
    font-family: 'Lato', sans-serif;
    color: #333;
    font-size: 1rem;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.22rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 0.88rem;
}

h5.italic {
    font-weight: 500;
}

b,
strong {
    font-weight: 600;
}


/*
 *
 * Buttons
 */

.btn {
    border-radius: 8px;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: var(--blau);
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
    background: var(--blau);
    border-color: var(--blau);
    transition: background 300ms ease;
    color: #fff !important;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
    background: var(--blau);
    border-color: var(--blau);
    color: #fff !important;
}

.navbar {
    margin-bottom: 0;
}

.navbar li a:link,
.navbar li a:visited,
.navbar li a:hover,
.navbar li a:active,
.navbar li a:focus {
    font-size: 14px;
    text-decoration: none;
}

.navbar-default {
    background-color:transparent;
    border: none;
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-nav>li>div>a {
    font-weight: 700;
    /*font-size: 28px;
    line-height: 34px;*/
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>div>a:hover {
    color: var(--blau);
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: var(--blau);
    background-color: transparent;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color:  var(--blau);
    background-color: transparent;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    color: var(--blau);
    background-color: transparent;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    color: #333;
    text-decoration: none;
    background-color: #fff;
}

.pagination>.active>a {
    background-color: var(--blau);
    border-color: var(--blau);
}

.xxx-pagination {
    font-family: 'Lato', sans-serif;
}

.navbar-default .navbar-collapse {
    border: none;
}

.navbar-default .navbar-toggle {
    border: none;
    margin-right: 0;
}

.navbar-default .navbar-toggle .icon-bar {
    width: 35px;
    height: 4px;
    margin-bottom: 5px;
    background-color: var(--blau);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background: none;
}

.breadcrumb {
    background-color: #fff;
}

.breadcrumb>li,
.breadcrumb>li>a {
    color: #666;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}

.breadcrumb>li {
    color: var(--blau);
}


.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    color: var(--blau);
    background: #fff;
}


/*
 *
 * Header
 */

.headimg {
    padding-top: 35px;
    padding-bottom: 25px;
}

.headimg img {
    max-width: 100%;
}


/*
 *
 * Navigation
 */

.navbar_mobile_menu {
    float: right;
    width: 40%;
    padding: 10px;
    background: #fff;
}

.navbar_mobile_menu>div,
.navbar_mobile_umenu>div {
    padding: 5px;
}

.navbar_mobile_menu>.active>a>div>p,
.navbar_mobile_umenu>.active>a>div>p {
    color: var(--blau);
}

.navbar_mobile_menu>div>a>div>p>i,
.navbar_mobile_umenu>div>a>div>p>i {
    padding-right: 5px;
    color: var(--blau);
    font-size: 16px;
}

.navbar_mobile_umenu {
    float: right;
    width: 40%;
    padding: 10px;
    background: #fff;
    -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
}

.navbar-icon>a>i {
    padding: 10px;
    color: var(--blau);
    font-size: 25pt;
}

.navbar-logo {
    display: none !important;
}

.navbar-logo img {
    max-height: 100%;
    max-width: 100%;
}

.nav>li>div>a {
    padding: 10px 15px;
}

.navbar-nav>li>div {
    padding-top: 10px;
    padding-bottom: 10px;
}

/*
 *
 * Main
 */

.searchform {
    margin-bottom: 25px;
}

.searchform>.input-group>.input-group-addon {
    color: var(--blau);
    background-color: transparent;
}

.nav-stacked .sidemenu_head {
    min-height: 30px;
    padding: 5px;
}

.nav-stacked .sidemenu_row {
    min-height: 30px;
    border-top: 1px solid #333;
}

.nav-stacked>li.active>a,
.nav-stacked>li.active:hover>a {
    color: var(--blau);
    background: #fff;
}

.nav-stacked .sidemenu_row>a>span {
    margin-top: 6px;
}

.nav-stacked .sidemenu_row>a {
    padding: 12px 5px 9px 5px;
}

.nav-stacked .sidemenu_row>a:hover {
    color: var(--blau);
    background-color: #fff;
}

.sidemenu_ug_row {
    min-height: 30px;
    margin-left: -10px;
    margin-right: -1px;
    padding-top: 5px;
    padding-left: 20px;
    list-style: none;
    text-align: left;
}

.nav>li>a:hover {
    background-color: #fff;
}

.sidemenu_ug_row>a:hover {
    color: var(--blau);
    background: #fff;
}

li.sidemenu_ug_row:hover {
    color: var(--blau);
    background: #fff;
}

.sidemenu_ug_row.active>a {
    color: var(--blau);
    background: #fff;
}

#content .breadcrumb {
    padding: 0;
    background-color: #fff;
}

.wk_container {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.autocomplete {
    position: relative;
    width: 100%;
}

.autocomplete ul {
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 1;
    width: 100%;
    padding-left: 0;
    border-left: 1px solid #888;
    border-right: 1px solid #888;
    border-bottom: 1px solid #888;
}

.autocomplete li {
    width: 100%;
    padding: .4em 1em;
    text-align: left;
    list-style: none;
    background-color: #fff;
}

.autocomplete li.active {
    width: 100%;
    background-color: #4bf;
}

.autocomplete .highlight {
    background-color: #e2e2e2;
}

.autocomplete li.active .highlight {
    color: #fff;
    background: #666;
}


/*
 *
 * Footer
 */

.foot_logos {
    text-align: right;
}

.foot_logos>a>img,
.foot_logos>img {
    padding: 10px;
}

div.foot1 {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    border-top: 1px solid #333;
}

div.foot2>ul>li>a {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}

div.foot2>ul>li:hover>a {
    color: var(--blau);
    font-family: 'Lato', sans-serif;
    background-color: #fff;
}

.xxx--page-footer--block--social-media {
    margin-bottom: 30px;
}

.xxx--page-footer--block--social-media ul {
    display: flex;
    justify-content: space-around;
}

.xxx--page-footer--block--social-media ul img {
    width: 32px;
    height: 44px;
}

/*
 *
 * Warenkorb Preview
 */

.warenkorbvoransicht_kopf {
    height: 40px;
    padding: 10px;
    background-color: var(--blau);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
}

.warenkorbvoransicht_kopf a {
    color: white;
    font-weight: bold;
}

.warenkorbvoransicht_zeile {
    padding: 10px 0 10px 0;
    font-size: 12px;
}

.warenkorbvoransicht_zeile a:hover {
    opacity: .5;
}

.warenkorbvoransicht_preis {
    padding: 0 5px 5px 5px;
    text-align: center;
}

.warenkorbvoransicht_preis>a>span {
    color: #000;
    font-size: 15px;
    font-weight: bold;
}


/*
 *
 * Artikelgruppen
 */

div.artikelgruppen_container {
    margin-bottom: 25px;
    border: 1px solid #e6e6e6;
    border-radius: 5px 5px 5px 5px;
}

div.artikelgruppen_container:hover {
    border: 1px solid var(--blau);
}

.artikelgruppen_container_image {
    position: relative;
    padding-top: 75%;
    border-bottom: 1px solid #333;
}

.artikelgruppen_container_image>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.artikelgruppen_container_image .overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.artikelgruppen_container:hover .artikelgruppen_container_image .overlay {
    display: block;
}

.artikelgruppen_container_image .overlay .background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    opacity: .6;
    background: #ccc;
}

.artikelgruppen_container_image .overlay .text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    padding: 10%;
    text-align: center;
}

.artikelgruppen_container_image .overlay .text p {
    font-weight: bold;
    font-size: 20px;
}

.artikelgruppen_container_image_layer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    margin-top: 30%;
    padding-top: 10px;
    text-align: center;
    background: none repeat scroll 0 0 black;
    /* Fallback for web browsers that don't support RGBa */
    background-color: rgb(0, 0, 0);
    /* RGBa with .6 opacity */
    background-color: rgba(0, 0, 0, .9);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99000000", endColorstr="#99000000");
    /* For IE 8*/
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#99000000", endColorstr="#99000000");
}

.artikelgruppen_container_image_layer span {
    color: white;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

div.artikelgruppen_container div.artikelgruppen_container_titel {
    height: 40px;
    padding: 10px;
    font-weight: 300;
}

div.artikelgruppen_container:hover div.artikelgruppen_container_titel {
    color: #209bd7;
}

.artikelgruppen_container_titel>span {
    float: right;
}


/*
 *
 * Artikellisten
 */

div.artikelliste_limit_text {
    float: right;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

div.artikelliste_limit_text>.aktiv {
    color: var(--blau);
}

div.artikelliste_darstellung_text {
    float: right;
    margin-right: 25px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
}

div.artikelliste_darstellung_text>.aktiv {
    color: var(--blau);
}

table.table>thead.artikelliste_table_head>tr>th {
    color: #666;
}

table.table>thead.artikelliste_table_head>tr>th.aktiv {
    color: var(--blau);
}


/*
 *
 * Artikellistentabelle
 */

table.artikelliste_table>thead.artikelliste_table_head .artikelliste_table_head_bildspalte {
    width: 10%;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_bildspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table>thead.artikelliste_table_head .artikelliste_table_head_artikelnrspalte {
    width: 20%;
    text-align: center;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_artikelnummerspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_infospalte {
    vertical-align: middle;
}

table.artikelliste_table>thead.artikelliste_table_head .artikelliste_table_head_bestandspalte {
    width: 5%;
    text-align: center;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_bestandspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table>thead.artikelliste_table_head .artikelliste_table_head_preisspalte {
    width: 15%;
    text-align: center;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_preisspalte {
    text-align: center;
    vertical-align: middle;
}

table.artikelliste_table>tbody.artikelliste_table_body .artikelliste_table_body_aktionsspalte {
    width: 15%;
    text-align: center;
    vertical-align: middle;
}

.artikelliste_liste_bildcontainer {
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(180, 180, 180);;
    border-radius: 4px;
}

.artikelliste_liste_bildcontainer>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
}

div.artikelliste_wk_button>span>i {
    float: right;
    color: var(--blau);
    cursor: pointer;
}

div.artikelliste_wk_button>span {
    background: transparent;
}

.wk_button_icon_container>i {
    padding-top: 25px;
    color: var(--blau);
    font-size: 40px;
}

.preis>p {
    font-weight: bold;
}

div.artikelliste_kachel_container {
    position: relative;
    min-height: 320px;
    margin-bottom: 20px;
}

div.artikelliste_kachel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: fit-content;
    border: 1px solid #e6e6e6;
}

div.artikelliste_kachel:hover {
    z-index: 3;
    box-shadow: 0 0 25px #333;
    background: #eee;
    height: fit-content;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table {
    border-top: 1px solid black;
}

div.artikelliste_kachel:hover {
    border: 1px solid #209bd7;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table {
    margin-bottom: 0;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_artikel {
    height: 60px;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_info {
    height: 0;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_info>td>div {
    font-size: 14px;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_artikel>td.artikelliste_kachel_table_bez {
    width: 90%;
    font-weight: 300;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_artikel>td.artikelliste_kachel_table_bestand {
    width: 10%;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_preis {
    width: 100%;
    height: 90px;
    font-weight: 300;
    text-align: right;
}

div.artikelliste_kachel>div.artikelliste_kachel_infocontainer>table.table>tbody>tr.artikelliste_kachel_table_zeile_preis>td {
    vertical-align: bottom;
}

div.artikelliste_kachel:hover>table.table>tbody>tr.artikelliste_kachel_table_zeile_preis>td>div>p {
    color: #209bd7;
}

.artikelliste_kachel_table_zeile_preis a {
    color: #209bd7;
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
}

div.artikelliste_kachel:hover>table.table>tbody>tr>td.artikelliste_kachel_table_bez {
    color: #209bd7;
}

div.artikelliste_kachel tr.artikelliste_kacel_table_zeile_aktion {
    display: none;
}

div.artikelliste_kachel:hover tr.artikelliste_kacel_table_zeile_aktion {
    display: table-row;
}

div.artikelliste_kachel:hover .artikelliste_kachel_details_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .8;
    background-color: #ccc;
}

div.artikelliste_kachel .artikelliste_kachel_details {
    display: none;
}

div.artikelliste_kachel:hover .artikelliste_kachel_details {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 10%;
    font-weight: bold;
    text-align: center;
}

.artikelliste_kachel_bildcontainer {
    position: relative;
    padding-top: 75%;
}

.artikelliste_kachel_bildcontainer>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.artikelliste_kachel_bildcontainer>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.artikelliste_me_pe.input-group>.input-group-addon {
    height: 34px;
    background: transparent;
}

.artikelliste_me_pe.input-group>.input-group-addon:first-child {
    border-right: 1px solid #ccc;
}

.artikelliste_me_pe.input-group>.input-group-btn>.btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}


/*
 *
 * Artikeldetail
 */

.artdetail_blaettern_icon {
    color: #209bd7;
    font-size: 20pt;
    cursor: pointer;
    margin-bottom: 5px;
}

.artdetail_blaettern_icon.fa-arrow-right {
    float: right;
}

.blaettern {
    text-align: center;
    white-space: nowrap;
    line-height: 30px;
}

.return {
    max-width: 100px;
    float: right;
}

div.artikeldetail_info div {
    min-height: 20px;
    padding-bottom: 10px;
    font-size: 14px;
}

div.artikeldetail_info h4 {
    font-weight: 300;
    font-size: 18px;
}

.artikeldetail_hauptbild_container {
    position: relative;
    /*padding-top: 100%;*/
    /*border: 1px solid rgb(180, 180, 180);*/
    /*border-radius: 4px;*/
    /* margin-bottom: 15px; */
}

.artikeldetail_hauptbild_container>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    cursor: zoom-in;
}

#artikeldetail_overlay {
    display: none;
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 10;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    background: #fff no-repeat;
    background-size: 200%;
}

.artikeldetail_alternativbilder_container {
    position: relative;
    padding-top: 50%;
    border: 1px solid var(--blau);
    /*border-radius: 4px;*/
}

.artikeldetail_alternativbilder_container>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_slider_container {
    position: relative;
    width: 100%;
    height: 300px;
    padding-left: 20px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 4px;
}

.artikeldetail_slider_container>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

/*
.artikeldetail_select_xs div {
    padding-bottom: 10px;
}
*/

.artikeldetail_select_xs select {
    color: #555;
    background-color: #fff;
}

.artikeldetail_option .input-group>.input-group-addon {
    height: 34px;
    background: transparent;
}

.artikeldetail_aktionspreis_altpreis {
    color: #333;
    font-size: 18px;
    font-weight: 300;
    text-decoration: line-through;
}

.artikeldetail_preis p {
    color: #333;
    font-size: 22px;
    font-weight: 300;
}

div.artikeldetail_preisbemerkung {
    text-align: right;
}

div.artikeldetail_preisbemerkung>p,
div.artikeldetail_preisbemerkung>p>a {
    font-size: 10px;
}

div.artikeldetail_button {
    text-align: center;
}

/*div.artikeldetail_button>div {
    padding-bottom: 10px;
}*/

div.artikeldetail_button>div>i,
div.artikeldetail_button>div>div>i {
    color: #209bd7;
    font-size: 34px;
    cursor: pointer;
}

div.artikeldetail_button i.btn-merkzettel {
    float: left;
}

/* div.artikeldetail_button>div i.btn-angebot {} */

div.artikeldetail_button>div>i.btn-warenkorb {
    float: right;
}


.artikeldetail_tabset>.nav-tabs {
    border: none;
}

.artikeldetail_tabset>.nav-tabs>li.active>a {
    color: var(--blau);
    font-size: 14px;
    border: none;
    border-bottom: 4px solid var(--blau);
}

.artikeldetail_tabset>.nav-tabs>li>a {
    color: #333;
    font-size: 14px;
    border: none;
}

.artikeldetail_tabset>.nav-tabs>li>a:hover {
    color: #209bd7;
    background-color: transparent;
}

.artikeldetail_tabset>.tab-content {
    padding: 40px 10px 10px 10px;
    /*border: 1px solid #209bd7;*/
    /*border-radius: 0 5px 5px 5px;*/
}

/*
.artikeldetail_tab {
    border: 1px solid #209bd7;
    border-radius: 5px 5px 0 0;
}
*/

div.artikeldetail_artikelanhang>div>a {
    color: #333;
    font-size: 14px;
    font-weight: 300;
}

.artikeldetail_bild_container {
    position: relative;
    width: 100%;
    height: 100px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 4px;
}

.artikeldetail_bild_container>img,
.artikeldetail_bild_container>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_stueckliste_container {
    position: relative;
    width: 100%;
    /* height: 100px; */
    border: 1px solid rgb(180, 180, 180);
    border-radius: 4px;
    text-align: center;
}

.artikeldetail_stueckliste_container>picture>img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

.artikeldetail_zubehoer_container {
    margin-top: 20px;
}

.artikeldetail_zubehoer_container>.carousel>.carousel-control {
    width: 0;
}

div.artikeldetail_slider>.carousel-control {
    width: 10%;
    margin-top: 30%;
    color: #209bd7;
    font-size: 30px;
    background-image: none;
    text-shadow: none;
    opacity: 1;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_bildspalte {
    width: 20%;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_infospalte {
    width: 50%;
    text-align: left;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_preisspalte {
    width: 30%;
    padding-top: 30px;
}

.artikeldetail_alternativartikel_tabelle .artikeldetail_alternativartikel_preisspalte>.artikeldetail_alternativartikel_preis>p {
    text-align: right;
    font-weight: 300;
    font-size: 18px;
}

.artikeldetail_variantenauswahl>div {
    margin-bottom: 10px;
}

.artikeldetail_variantenauswahl .input-group-addon {
    min-width: 70px;
}

.mengumrechnung>tbody>tr:hover {
    opacity: .7;
}

.sachm:after {
    content: ', ';
}

.sachm:last-child:after {
    content: '';
}


/*
 *
 * Merkzettel
 */

div.merkzettel_info a h4 {
    font-size: 14px;
    min-height: 30px;
}

div.merkzettel_info a h4:hover {
    color: var(--blau);
}

div.merkzettel_info .input-group span {
    color: var(--blau);
    cursor: pointer;
}

div.merkzettel_info .input-group .input-group-addon {
    background: transparent;
}


/*
 *
 * Konto
 */

.konto_xs_accordion {
    margin-top: 20px;
}

.konto_xs_accordion>div>div>.panel-default {
    border: none;
    box-shadow: none;
}

.konto_xs_accordion>div>div>.panel-default>.panel-heading {
    background-color: #fff;
    border: none;
}

.kontosuche>.input-group>div.input-group-addon {
    color: #209bd7;
    background-color: transparent;
}

.konto_down-up {
    text-align: center;
}

.konto_passwortfeld {
    -webkit-text-security: disc;
}

/*
 *
 * Warenkorb
 */

.wk_schnellerfassung {
    margin-bottom: 30px;
}

.wk_schnellerfassung>div {
    margin-bottom: 10px;
}

.wk_schnellerfassung .input-group>.input-group-addon {
    min-width: 100px;
}

.wk_schnellerfassung .input-group>.form-control {
    text-align: center;
}

.wk_stufen {
    margin-bottom: 30px;
}

.wk_stufen .symbol {
    float: left;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #333;
    font-size: 25px;
    text-align: center;
}

.wk_stufen .symbol.active {
    color: var(--blau);
}

.wk_stufen .symbol.success {
    color: #006400;
}

.wk_stufen .title {
    padding-left: 35px;
    line-height: 30px;
    font-size: 20px;
}

.wk_stufen .title.active {
    color: var(--blau);
}

.wk_stufen .title.success {
    color: #006400;
}

.wk_footer {
    margin-top: 20px;
}

.wk_footer>div {
    margin-bottom: 10px;
}

.wk_login_bereich .panel .panel-title>input {
    margin-right: 10px;
}

.wk_login_bereich .panel .main {
    height: 165px;
}

.wk_login_bereich .wk_login_panel>.main>div {
    margin-bottom: 10px;
}

.wk_belegsumme {
    padding: 5px;
    color: #fff;
    border: 1px solid var(--blau);
    background-color: var(--blau);
    font-weight: bold;
    text-align: right;
}

.wk_versandcontainer {
    margin: 20px 0;
    padding: 5px;
    background-color: #eee;
    border: 1px solid #ccc;
}

.wk_versandcontainer .checkbox input {
    margin-right: 5px;
}

.wk_bestellabschluss_bemerkung_container {
    padding: 10px;
    border: 1px solid #ccc;
}

.wk_bestellabschluss_btn>div {
    margin-bottom: 10px;
}

.wk_pos_image_container {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.wk_pos_image_container img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.wk_pos_zeile {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.wk_pos_zeile>div {
    padding-top: 10px;
}

.wk_pos_zeile .wk_pos_menge_zelle {
    line-height: 34px;
    font-size: 16px;
    font-weight: bold;
}

.wk_pos_zeile .wk_pos_gesamtpreis_zelle {
    line-height: 34px;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
}

.wk_pos_zeile .wk_pos_delete_zelle {
    font-size: 16px;
    text-align: center;
}

.wk_pos_zeile .wk_pos_info_zelle .titel {
    padding-bottom: 10px;
}

.wk_pos_zeile .wk_pos_info_zelle .titel p {
    font-weight: bold;
    font-size: 16px;
}

.wk_pos_zeile .wk_pos_change_link a {
    text-decoration: underline;
}

.wk_pos_zeile .wk_lg_hinweis {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    color: red;
}

.wk_pos_smart_foot {
    padding: 10px;
    background-color: #eee;
}

.wk_table>thead>tr>th {
    text-align: center;
}

.wk_table>tbody>tr>td {
    vertical-align: middle;
    text-align: center;
}

.wk_table .titel p {
    margin: 0;
    font-weight: bold;
    text-decoration: underline;
}

.wk_table td.info_spalte {
    text-align: left;
}

.wk_table div.lg_hinweis {
    text-align: left;
    color: red;
}

.belegliste_kopf_pos {
    width: 5%;
}

.belegliste_kopf_artikelnummer {
    width: 20%;
}

.belegliste_kopf_menge {
    text-align: center;
}

.belegliste_inhalt_menge {
    text-align: center;
}

.belegliste_kopf_mengeneinheit {
    text-align: center;
}

.belegliste_inhalt_mengeneinheit {
    text-align: center;
}

.belegliste_kopf_preiseinheit {
    text-align: right;
}

.belegliste_inhalt_preiseinheit {
    text-align: right;
}

.belegliste_kopf_einzelpreis {
    text-align: right;
}

.belegliste_inhalt_einzelpreis {
    text-align: right;
}

.belegliste_kopf_rabatt {
    text-align: right;
}

.belegliste_inhalt_rabatt {
    text-align: right;
}

.belegliste_kopf_gesamtpreis {
    text-align: right;
}

.belegliste_inhalt_gesamtpreis {
    text-align: right;
}

.belegliste_kopf_bestand {
    text-align: right;
}

.belegliste_inhalt_bestand {
    width: 5%;
    max-width: 5%;
    text-align: center;
}

#continue_shopping,
#BestellenWeiterKL,
#AnmeldenKL {
    float: left;
    width: 148px;
    height: 30px;
    margin-top: 5px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

.td_warenkorb1_untere_zeile {
    width: 650px;
}

.wk_registrieren_button {
    width: 150px;
    height: 30px;
    margin-top: 20px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    text-align: center;
    cursor: pointer;
}

.wk_registrieren_button>a {
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}

.textfeld1 {
    height: 18px;
    font-size: 8pt;
}

.textarea1 {
    font-size: 8pt;
}

.font8 {
    font-size: 8pt;
    font-weight: normal;
}

.font9 {
    font-size: 9pt;
    font-weight: normal;
}

.font10 {
    font-size: 10pt;
    font-weight: normal;
}

.font8b {
    font-size: 8pt;
    font-weight: bold;
}

.font9b {
    font-size: 9pt;
    font-weight: bold;
}

.font10b {
    font-size: 10pt;
    font-weight: bold;
}

.font9blue {
    color: #00F;
    font-size: 9pt;
    font-weight: bold;
}

.font9red {
    color: #C00;
    font-size: 9pt;
}

.font9green {
    color: #006400;
    font-size: 9pt;
}

.font9greenb {
    color: #006400;
    font-size: 9pt;
    font-weight: bold;
}

.copyright {
    font-weight: 300;
    font-size: 12px;
}

.table_rahmentab {
    width: 100%;
    font-size: 12px;
}

.table_mitte_inhalt {
    font-size: 12px;
}

.bg_inhalt {
    width: 100%;
    background-color: #fff;
}

.mitte {
    width: 100%;
}

.td_mitte_inhalt_tabkopf {
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 12px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.td_mitte_inhalt {
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}

.warenk_table_bestellbereich {
    padding: 3px;
}

.warenk_table_bestellflaeche {
    margin-bottom: 20px;
    padding: 2px 2px 2px 0;
}

.warenk_td_bestell_font {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.warenk_table_bestellung_kopf {
    margin-bottom: 3px;
    margin-top: 10px;
    padding: 3px;
    border-bottom: 1px solid black;
}

.warenk_tr_kundendaten {
    width: 100%;
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_tr_bestellung_kopf {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-bottom: 1px solid black;
}

.warenk_tr_bestellung_ohneInhalt {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
    border-spacing: 3px;
    border-collapse: separate;
}

.warenk_table_bestellung_fuss {
    margin-top: 3px;
    margin-bottom: 3px;
}

.warenk_td_bestell_buttons {
    margin-top: 5px;
    font-size: 9pt;
    font-weight: normal;
}

.seiten_ueberschrift {
    width: 100%;
    font-size: 12pt;
    font-weight: 300;
}

.table_formularbox {
    margin: 2px;
    padding: 2px;
}

.td_formular_kopf {
    color: #000;
    font-size: 10pt;
    font-weight: 300;
}

.td_formular_beschriftung {
    width: 100px;
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}

.td_formular_text {
    font-size: 9pt;
    font-weight: 300;
}

.form_meldung_td {
    color: red;
    font-size: 10pt;
    font-weight: 300;
}

.beleg_mitte {
    margin-top: 7px;
    padding: 5px;
    background-color: #fff;
}

.fontbelegarchiv {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.warenk_lieferbedingung {
    color: #000;
    font-size: 9pt;
    font-weight: 300;
}

.table_warenkorb_teil2 {
    margin-top: 9px;
}

.select_versandarten {
    border: 1px solid #c0c0c0;
    color: #13335c;
    font-family: 'Lato', sans-serif;
    font-size: 8pt;
}

.select_zahlungsarten {
    width: 170px;
    border: 1px solid #c0c0c0;
    color: #13335c;
    font-family: 'Lato', sans-serif;
    font-size: 8pt;
}

.beleg_lfb_texte_table {
    width: 100%;
}

.beleg_lfb_texte_td1 {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    font-weight: 300;
}

.beleg_lfb_texte_td2 {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    font-weight: normal;
}

.beleg_zb_texte_table {
    width: 100%;
    margin-left: 3px;
}

.beleg_zb_texte_td1 {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    font-weight: 300;
}

.beleg_zb_texte_td2 {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    font-weight: normal;
}

.wk1_a_textfeld1 {
    width: 50px;
    height: 18px;
    border: 1px solid #6f6f6f;
    font-size: 8pt;
}

.td_warenkorb_buttonleiste1_aktiv_variabel {
    width: 125px;
    height: 40px;
}

.td_warenkorb_buttonleiste1_passiv_variabel {
    width: 125px;
    height: 40px;
}

.td_warenkorb_buttonleiste2_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste2_passiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste3_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste3_passiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste4_aktiv_variabel {
    width: 125px;
}

.td_warenkorb_buttonleiste4_passiv_variabel {
    width: 125px;
}

.table_warenkorb_buttonleiste {
    width: 100%;
    height: 50px;
    margin-top: 8px;
    border-bottom: 1px solid #c0c0c0;
}

.td_warenkorb_buttonleiste1_aktiv {
    width: 125px;
    height: 40px;
    background: url('../../3xios/images/button_wk1_a.png') no-repeat;
}

.td_warenkorb_buttonleiste1_passiv {
    width: 125px;
    height: 40px;
    background: url('../../3xios/images/button_wk1_p.png') no-repeat;
}

.td_warenkorb_buttonleiste2_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk2_a.png') no-repeat;
}

.td_warenkorb_buttonleiste2_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk2_p.png') no-repeat;
}

.td_warenkorb_buttonleiste3_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk3_a.png') no-repeat;
}

.td_warenkorb_buttonleiste3_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk3_p.png') no-repeat;
}

.td_warenkorb_buttonleiste4_aktiv {
    width: 125px;
    background: url('../../3xios/images/button_wk4_a.png') no-repeat;
}

.td_warenkorb_buttonleiste4_passiv {
    width: 125px;
    background: url('../../3xios/images/button_wk4_p.png') no-repeat;
}

.td_warenkorb_buttonleiste1_zelle_unten {
    height: 10px;
}

select#sel_lfb.select_versandarten {
    width: 170px;
    height: 20px;
}

.abstand_summenfeld2 {
    padding-right: 50px;
}

.td_warenkorb1_untere_zeile {
    width: 65%;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}

.meldungstext {
    color: #f00;
}

.tr_mitte_inhalt_0 {
    padding: 10px 0;
}

.table_warenkorb1_untere_zeile {
    margin-top: 15px;
}

.warenk_td_summen {
    padding-left: 50px;
}

.button_fett_konto {
    width: 207px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    background: #b22413;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
}

#sm_regneu,
#Zurueck,
#Bestellen,
#Verwerfen,
#Aendern,
#ZurueckFensterZu,
#sm_los_pw {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK,
#NeukundeRegistrieren,
#Anmelden,
#Abmelden {
    float: right;
    width: 148px;
    height: 33px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 0 5px 8px 5px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK2,
#WeiterWK3_ak,
#Drucken {
    float: right;
    width: 150px;
    height: 30px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    -webkit-appearance: none;
}

#WeiterWK3_nk {
    width: 150px;
    height: 30px;
    margin-top: 5px;
    margin-right: 12px;
    line-height: 30px;
    border: 1px solid #209bd7;
    border-radius: 4px;
    background: #209bd7;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
}

.select_beleg2 {
    width: 100%;
    max-width: 195px;
    padding-top: 3px;
    padding-left: 5px;
    border: 1px solid #aaa;
    background-color: #fff;
    box-shadow: inset 0 0 8px #ccc;
    text-align: left;
}

.td_formular_kopf_beleg2 {
    height: 35px;
    max-height: 40px;
    padding: 5px 0 0 8px;
    line-height: 12px;
    vertical-align: top;
    background: url('images/hg-title.png');
    font-size: 11pt;
    text-align: left;
}

input.textfeld_wk_regstartbereich,
input#Artikelnummer_suche,
input#wk1_a_textfeld1,
input.textfeld_registrieren,
input.textfeld1,
input.textfeld_beleg2,
input.regi_textfeld_al,
select.select_registrieren,
select.regi_select_al,
select.select_zahlungsarten,
select.select_beleg2 {
    display: block;
    height: 30px;
    padding: 0 12px;
    line-height: 30px;
    line-height: 1.42857143;
    font-size: 14px;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

select.select_registrieren,
select.regi_select_al {
    width: 200px;
}

#tx_pw1,
#tx_pw2 {
    max-width: 200px;
}

input.button_normal,
input#uebernehmen,
input#sm_import,
input#zahlen {
    width: 148px;
    height: 30px;
    margin-top: 5px;
    line-height: 30px;
    border: 1px solid #209bd7;
    background: #209bd7;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    -webkit-appearance: none;
}

input.button_normal#SeiteDrucken {
    float: right;
    margin: 2px;
}

input.button_normal#Zu {
    float: left;
    margin: 2px;
}

.warenk_lieferbedingung td {
    padding-top: 5px;
}

.wk_zahlungslink_button {
    display: contents;
}

.wk_footer .checkbox a {
    color: var(--blau);
    text-decoration: underline;
}


/*
 *
 * Portal Padding
 */

.xxx--portal---padding,
.xxx--portal---padding-mod .xxx--portal--mod,
.xxx--portal---padding-block .xxx--portal--mod--block {
    padding: 15px;
}


/*
 *
 * RSS
 */

div.rss-items {
    border-top: 1px solid grey;
}

div.rss-items h6 {
    font-weight: bold;
}

div.rss table {
    width: 100%;
    margin-bottom: 10px;
}

div.rss a {
    text-decoration: underline;
}

div.rss-items p:nth-child(4) {
    color: grey;
    font-style: italic;
    text-align: right;
}

.rss .row {
    margin-left: 0;
}

.rss-text {
    padding-left: 0;
}


/*
 *
 * Sonstiges (MISC)
 */

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.carousel-indicators {
    display: block;
}

.artikelslider .carousel-indicators {
    position: relative;
    bottom: 0;
    top: 10px;
}

.carousel-indicators li {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    background-color: #6576B1;
    border: 1px solid #6576B1;
    border-radius: 30px;
}

.carousel-indicators .active {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    background-color: var(--blau);
    border: 1px solid #fff;
}

a.carousel-control span.glyphicon {
    display: none;
}

.italic {
    color: #ccc;
    font-style: italic;
}

img.img-thumbnail {
    width: 100%;
}

.circle_red,
.circle_red span,
.xxx--product--availability---r,
.xxx--product--availability---r span {
    color: #f00 !important;
}

.circle_yellow,
.circle_yellow span,
.xxx--product--availability---y,
.xxx--product--availability---y span {
    color: #ffcc00 !important;
}

.circle_green,
.circle_green span,
.xxx--product--availability---g,
.xxx--product--availability---g span {
    color: #00B050 !important;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;
}

span.modal_close_button {
    position: absolute;
    top: 0;
    right: 0;
    margin: -20px -20px 0 0;
    padding: 2px 3px 0 4px;
    background-color: transparent;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    color: #000;
    cursor: pointer;
}

form.login_modal_form>.input-group>.input-group-addon {
    color: var(--blau);
    background-color: transparent;
}

.gal-thumbnail {
    background-color: transparent;
}

div.galspace {
    margin-bottom: 10px;
    padding: 0 5px 0 0;
}

div.galeriepic {
    min-height: 0;
    height: auto;
}

div.galbild_vorsch {
    position: relative;
    width: 100%;
    height: 200px;
}

div.galbild_vorsch img {
    /* position: absolute; */
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

.gallist div.galbild_vorsch {
    position: relative;
    width: 100%;
    height: 200px;
}


.gallist div.galbild_vorsch img {

    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media all and (-ms-high-contrast:none) {
     /* IE11 */
    *::-ms-backdrop,
    .gallist div.galbild_vorsch img {
        height: auto;
        position: absolute;
        bottom: 0;

    }
    *::-ms-backdrop,
    .gallist div.galbild_vorsch {
        background-color: #e6e6e6;
        border-radius: 4px;
    }
    /* IE11 */
}


div.galbild_vorsch img.galerie {
    background-size: cover;
}

div.gal_list div.galerie_caption {
    height: 1em;
    max-height: 1em;
    margin: -10px 0 10px 0;
    padding: 0;
}

div.galerie_caption.gal_slide {
    margin: 20px;
}

.modal_img_container {
    position: relative;
    width: 100%;
    height: 500px;
}

.modal_img_container>picture>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    border-radius: 4px;
    cursor: pointer;
}

#modalimage_gal-description {
    margin-top: -18px;
}

#modalimage_gal-description span {
    padding: 5px 10px 8px 10px;
    border-radius: 5px;
    background-color: #fff;
}

label.form_elem_desc,
div.labelb {
    padding: 0 10px;
    color: #333;
    font-family: 'Lato', sans-serif;
    font-style: italic;
}

p.labelb {
    color: #333;
    font-family: 'Lato', sans-serif;
    font-style: italic;
}

input.form_elem_div.captchacode {
    width: 100px;
}

.itemnichtsichtbar {
    display: block;
    height: auto;
    border: 1px dotted #c0c0c0;
}

.itemnichtsichtbar h1,
.itemnichtsichtbar h4,
.itemnichtsichtbar h4 a,
.itemnichtsichtbar h5,
.itemnichtsichtbar p,
.itemnichtsichtbar a,
.itemnichtsichtbar div a,
.itemnichtsichtbar div label {
    color: #c0c0c0;
}

.adresse {
    margin-bottom: 15px;
}

.adresse div.galbild_vorsch {
    margin-bottom: 15px;
}

.gleicheHoehe {
    overflow: hidden;
}

.Hoehe {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}

.kachel_in_portal {
    padding-left: 0;
}

.hk-uk-img {
    float: left;
    clear: left;
    max-width: 100%;
    padding: 0 10px 10px 0;
}

.category-list,
.ranges-list,
.tags-list {
    padding-left: 10px;
    font-size: smaller;
    list-style: none;
}

.category-list li,
.ranges-list li,
.tags-list li {
    margin-left: 0;
    margin-bottom: 5px;
}

.artikelmodal_bildcontainer {
    position: relative;
    padding-top: 100%;
    width: 100%;
    border: 1px solid #333;
    border-radius: 4px;
}

.artikelmodal_bildcontainer>img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.category-list li ul {
    padding-left: 10px;
    border-left: 1px solid #ddd;
    list-style: none;
}

.category-list li a.active {
    font-weight: bold;
}

.category-list li a span {
    padding-left: 5px;
    color: gray;
    font-size: x-small;
}

.ranges-list li label {
    font-size: small;
    font-weight: normal;
}

.tags-list li label {
    padding-left: 5px;
    font-size: small;
    font-weight: normal;
}

.tags-list li input {
    margin-right: 5px;
}

.mengenformel_modal .modal-body .input-group .input-group-addon {
    min-width: 100px;
}

.mengenformel_modal .modal-body .input-group .form-control {
    max-width: 100px;
}


/*
 *
 * Druckansicht
 */

.druck_kopf {
    border-bottom: 2px solid #333;
}

.druck_kopf_links h4 {
    font-size: 14px;
}

.druck_kopf_links p {
    margin-bottom: 5px;
}

.druck_kopf_rechts h4 {
    border-bottom: 1px solid #333;
    font-size: 14px;
}

.druck_kopf_rechts p {
    margin-bottom: 5px;
}

.druck_kundendaten {
    border-bottom: 2px solid #333;
}

.druck_rechnungsanschrift h4 {
    font-size: 12px;
}

.druck_lieferanschrift h4 {
    height: 26px;
    font-size: 12px;
}

.druck_rechnungsanschrift label,
.druck_rechnungsanschrift p,
.druck_lieferanschrift label,
.druck_lieferanschrift p {
    margin-bottom: 0;
}

.druck_rechnungsanschrift .form-horizontal .form-group,
.druck_lieferanschrift .form-horizontal .form-group {
    margin-bottom: 5px;
}

.druck_bestelluebersicht h4 {
    font-size: 14px;
}

.druck_bestelluebersicht .table p {
    margin-bottom: 0;
}

.druck_bestelluebersicht .table thead th {
    padding: 5px;
}

.druck_bestelluebersicht .table tbody td {
    padding: 5px;
    vertical-align: middle;
}

.druck_bestelluebersicht {
    border-bottom: 2px solid #333;
}

.druck_bemerkung {
    border-bottom: 2px solid #333;
}

.druck_bemerkung h4 {
    font-size: 14px;
}

.druck_infotext {
    margin-top: 10px;
    font-weight: bold;
}

.druck_zahlung_lieferbedingung h4 {
    font-size: 14px;
}

.druck_zahlung_lieferbedingung {
    border-bottom: 2px solid #333;
}

.druck_button {
    margin-top: 20px;
}


/*
 *
 * RSS
 */

.rss-text img {
    float: left;
    width: 25%;
    margin-right: 10px;
    vertical-align: middle;
}

.rss-items {
    cursor: pointer;
}

.rss-items .rss-date,
.rss-items .btn-primary {
    float: right;
}


/*
 *
 * Extra Small (XS)
 */

@media (max-width: 767px) {
    html {
        font-size: 13px;
    }

    .warenk_table_bestellflaeche,
    .table_warenkorb_buttonleiste,
    .belegliste_kopf_preiseinheit,
    .belegliste_inhalt_preiseinheit,
    .belegliste_kopf_mengeneinheit,
    .belegliste_inhalt_mengeneinheit,
    .belegliste_kopf_bestand,
    .belegliste_inhalt_bestand,
    .belegliste_kopf_rabatt,
    .belegliste_inhalt_rabatt,
    .belegliste_kopf_pos,
    .belegliste_inhalt_pos,
    .belegliste_kopf_gesamtpreis,
    .belegliste_inhalt_gesamtpreis {
        display: none;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding: 5px 15px 5px 0px;
    }

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        display: flex;
        flex-direction: column;
    }

    .navbar__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon .navbar-icon i {
        font-size: 20pt;
    }

    .navbar-header-icon:before,
    .navbar-header-icon:after {
        content: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }

    .navbar_mobile_menu {
        float: right;
        width: 100%;
        background: #fff;
    }

    .navbar_mobile_menu>div,
    .navbar_mobile_umenu>div {
        padding: 5px;
    }

    .navbar_mobile_menu>.active>a>div>p,
    .navbar_mobile_umenu>.active>a>div>p {
        color: #209bd7;
    }

    .navbar_mobile_menu>div>a>div>p>i,
    .navbar_mobile_umenu>div>a>div>p>i {
        padding-right: 5px;
        color: #209bd7;
        font-size: 16px;
    }

    .abstand_summenfeld2 {
        padding-right: 0;
    }

    .artikeldetail_info {
        margin-top: 10px;
    }

    /* .artikeldetail_info .left {
        float: left;
    } */

    /* .artikeldetail_info .right {
        float: right;
    } */

    div.artikelliste_kachel_container {
        min-height: 400px;
    }

    .navbar-nav .open .dropdown-menu {
        padding-left: 30px;
    }

    .artikelliste_liste_bildcontainer {
        position: relative;
        width: 100%;
        /* height: 200px; */
        border: 1px solid rgb(180, 180, 180);
        border-radius: 4px;
    }

    /*.sidemenu {
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 0 !important;
        display: block !important;
        background-color: #FFF;
        z-index: 1000;
        -webkit-box-shadow: -10px 10px 15px 5px rgb(170 170 170 / 40%);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgb(170 170 170 / 40%);
    }*/

    .close-button {
        text-align: right;
        padding: 5px 2px;
        /*min-width: 275px;*/
    }
    .zukueckbtn {
        margin-bottom: 10px;
    }
    .artikel_kachel {
        width: 49%;
    }
    .carousel-indicators li {
        width: 10px;
        height: 10px;
        margin-right: 6px;
    }

    .carousel-indicators .active {
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
    .xxx---plr-0-sm {
        padding-left: 0;
        padding-right: 0;
    }
}


@media (max-width: 400px) {
    .artikel_kachel {
        width: 100%;
    }
}

/*
 *
 * Small (SM)
 */

@media (min-width: 768px) and (max-width: 991px) {

    html {
        font-size: 13px !important;
    }

    .navbar-header .collapse,
    .navbar-toggle {
        display: block !important;
    }

    .navbar-header {
        float: none;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon:before,
    .navbar-header-icon:after {
        content: none;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        position: absolute;
        z-index: 10000;
        width: 100%;
        background-color: #fff;
        -webkit-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        -moz-box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
        box-shadow: -10px 10px 15px 5px rgba(170, 170, 170, .4);
    }

    .navbar_mobile_menu {
        float: right;
        width: 40%;
        padding: 10px;
        background: #fff;
    }

    .navbar_mobile_menu>div,
    .navbar_mobile_umenu>div {
        padding: 5px;
    }

    .navbar_mobile_menu>.active>a>div>p,
    .navbar_mobile_umenu>.active>a>div>p {
        color: #209bd7;
    }

    .navbar_mobile_menu>div>a>div>p>i,
    .navbar_mobile_umenu>div>a>div>p>i {
        padding-right: 5px;
        color: #209bd7;
        font-size: 16px;
    }

    .navbar_mobile_umenu {
        float: right;
        width: 40%;
        padding: 10px;
        background: #fff;
    }

    .navbar-nav>li {
        float: unset;
    }

    .navbar-nav {
        width: 100%;
    }

    .navbar-nav .open .dropdown-menu {
        float: none;
        display: block;
        position: static;
        width: auto;
        margin-top: 0;
        border: 0;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    div.artikelliste_kachel_container {
        min-height: 400px;
    }

    .navbar-header {
        display: flex;
        flex-direction: column;
    }

    .navbar__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-header-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .carousel-indicators li {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }

    .carousel-indicators .active {
        width: 22px;
        height: 22px;
        margin-right: 12px;
    }
}


/*
 *
 * Medium (MD)
 */

@media (min-width: 992px) and (max-width: 1199px) {
    html {
        font-size: 15px;
    }
    .navbar-default .navbar-nav {
        margin-left: -30px;
    }
}


/*
 *
 * Large (LG)
 */

@media (min-width: 1200px) {
    .navbar-default .navbar-nav {
        margin-left: -15px;
    }
}


/*
 *
 * ELEMENTS
 */

iframe {
    width: 100% !important;
}

iframe[ng-src*="3xios/beleg1.php"] {
    min-height: 500px;
}


/**
 *
 * @package bootstrap
 * @extension
 */

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-fat {
    font-weight: bold;
}


/**
 *
 * @package reboot
 * @modifier
 */

.reboot---padding,
.reboot---padding-all,
.reboot---padding-all .container,
.reboot---padding-all .row,
.reboot---padding-all *[class*="col-"],
.reboot---padding-all-panel.panel-group,
.reboot---padding-all-panel .panel-group,
.reboot---padding-all-container.container,
.reboot---padding-all-container .container,
.reboot---padding-all-row.row,
.reboot---padding-all-row .row,
.reboot---padding-all-col[class*="col-"],
.reboot---padding-all-col *[class*="col-"] {
    padding: 0;
}

.reboot---margin,
.reboot---margin-all,
.reboot---margin-all .row,
.reboot---margin-all *[class*="navbar-"],
.reboot---margin-all .panel-group,
.reboot---margin-all-row.row,
.reboot---margin-all-row .row,
.reboot---margin-all-panel[class*="panel-"],
.reboot---margin-all-panel *[class*="panel-"],
.reboot---margin-all-panel .panel-group,
.reboot---margin-all-navbar[class*="navbar-"],
.reboot---margin-all-navbar *[class*="navbar-"] {
    margin: 0;
}

.reboot---border-radius,
.reboot---border-radius * {
    border-radius: 0 !important;
}


/**
 * flex
 * @package xxx
 * @component
 */

.xxx--flex-container {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    margin: 0 auto;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---fluid {
    width: 100%;
    max-width: unset;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---row {
    flex-direction: row;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---row-reverse {
    flex-direction: row-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---col {
    flex-direction: column;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---col-reverse {
    flex-direction: column-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---wrap {
    flex-wrap: wrap;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---wrap-reverse {
    flex-wrap: wrap-reverse;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---justify-center {
    justify-content: center;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-stretch {
    align-items: stretch;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-start {
    align-items: flex-start;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-end {
    align-items: flex-end;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-item-center {
    align-items: center;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-stretch {
    align-content: stretch;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-start {
    align-content: flex-start;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-end {
    align-content: flex-end;
}

*[class*="xxx-"][class*="--flex-container"].xxx--flex---align-content-center {
    align-content: center;
}

@media (max-width: 767px) {
    .xxx-xs--flex-container {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-xs--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 750px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-sm--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 970px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-md--flex---col {
        flex-direction: column;
    }
}

@media (min-width: 1200px) {
    .xxx-lg--flex-container {
        display: flex;
        flex-direction: row;
        max-width: 1170px;
        margin: 0 auto;
    }

    *[class*="xxx-"][class*="--flex-container"].xxx-lg--flex---col {
        flex-direction: column;
    }
}

.xxx--flex-container>*,
.xxx--flex-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 8.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-1 {
    flex-basis: 8.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-2 {
    flex-basis: 16.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-3 {
    flex-basis: 25%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-4 {
    flex-basis: 33.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-5 {
    flex-basis: 41.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-6 {
    flex-basis: 50%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-7 {
    flex-basis: 58.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-8 {
    flex-basis: 66.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-9 {
    flex-basis: 75%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-10 {
    flex-basis: 83.33%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-11 {
    flex-basis: 91.66%;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---base-12 {
    flex-basis: 100%;
}

@media (max-width: 767px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---base-12 {
        flex-basis: 100%;
    }
}

@media (min-width: 1200px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-1 {
        flex-basis: 8.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-2 {
        flex-basis: 16.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-3 {
        flex-basis: 25%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-4 {
        flex-basis: 33.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-5 {
        flex-basis: 41.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-6 {
        flex-basis: 50%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-7 {
        flex-basis: 58.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-8 {
        flex-basis: 66.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-9 {
        flex-basis: 75%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-10 {
        flex-basis: 83.33%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-11 {
        flex-basis: 91.66%;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---base-12 {
        flex-basis: 100%;
    }
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-0 {
    flex-grow: 0;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-1 {
    flex-grow: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-2 {
    flex-grow: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---grow-3 {
    flex-grow: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-0 {
    flex-shrink: 0;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-1 {
    flex-shrink: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-2 {
    flex-shrink: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---shrink-3 {
    flex-shrink: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-1 {
    order: 1;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-2 {
    order: 2;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-3 {
    order: 3;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-4 {
    order: 4;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-5 {
    order: 5;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-6 {
    order: 6;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-7 {
    order: 7;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-8 {
    order: 8;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-9 {
    order: 9;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-10 {
    order: 10;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-11 {
    order: 11;
}

*[class*="xxx-"][class*="--flex-item"].xxx--flex---order-12 {
    order: 12;
}

@media (max-width: 767px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-xs--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-sm--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-md--flex---order-12 {
        order: 12;
    }
}

@media (min-width: 1200px) {
    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-1 {
        order: 1;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-2 {
        order: 2;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-3 {
        order: 3;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-4 {
        order: 4;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-5 {
        order: 5;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-6 {
        order: 6;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-7 {
        order: 7;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-8 {
        order: 8;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-9 {
        order: 9;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-10 {
        order: 10;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-11 {
        order: 11;
    }

    *[class*="xxx-"][class*="--flex-item"].xxx-lg--flex---order-12 {
        order: 12;
    }
}


/**
 * page
 * @package xxx
 * @component
 */

.xxx--page-header {
    position: relative;
}

.xxx--page-header--block {
    padding: 5px 0;
}

.xxx--scroll-sticky .xxx--page-header--block {
    padding: 25px 0 0 0;
}


/**
 * language
 * @package xxx
 * @component
 */

.xxx--language--list .xxx--list-item+.xxx--list-item {
    margin-left: 10px;
}

.xxx--language--block {
    opacity: .3;
    background: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.xxx--scroll-sticky .xxx--language--list {
    position: absolute;
    right: 0;
    z-index: 1000;
}

.xxx--language--block.xxx---is-active,
.xxx--language--block:hover {
    opacity: 1;
    transition: opacity 300ms ease;
}

.xxx--language--icon,
.xxx--language--label {
    font-size: 30px;
}


/**
 * map
 * @package xxx
 * @component
 */

.xxx--map {
    display: block;
    width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
    border: 0 none;
    outline: 1px solid #ccc;
}


/**
 * list
 * @package xxx
 * @component
 */

.xxx--list---cleared {
    list-style: none;
    padding: 0;
    margin: 0;
}

.xxx--list---level .xxx--list---level {
    padding-left: 15px;
}

/*.xxx--list-item>.xxx--collapse {
    border-top: thin solid #666;
    border-bottom: thin solid #666;
}*/

.xxx--list-item>.xxx--collapse.xxx---is-collapsed {
    border-top: thin solid #fff;
    border-bottom: thin solid #fff;
}

.xxx--list-item:last-of-type>.xxx--collapse {
    border-bottom: thin solid #fff !important;
}


/**
 * table
 * @package xxx
 * @component
 */

.xxx--table {
    width: 100%;
}

.xxx--table---th-light th {
    font-weight: lighter;
}


/**
 * img-box
 * @package xxx
 * @component
 */

.xxx--img-box {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xxx--img-box---radial {
    border-radius: 3px;
}

.xxx--img-box---hover-shadow {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 300ms ease;
}

.xxx--img-box---hover-shadow:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .6);
}

.xxx--img-box--title {
    font-size: 14px;
}

.xxx--img-box--title---absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 15px;
    background: rgba(255, 255, 255, .8);
    z-index: 10;
}

.xxx--img-box--title---bottom-plain-grey {
    display: block;
    padding: 5px 8px;
    background: #eee;
    border-top: thin solid #999;
    z-index: 10;
}

.xxx--img-box--image {
    display: block;
    width: 100%;
}

*[class*="xxx--img-box-image---max-height-"],
.xxx--img-box--image.xxx--img-box--image---normal-size {
    width: auto;
}

.xxx--img-box-image---max-height-200 {
    max-height: 200px;
}

.xxx--img-box-image---max-height-300 {
    max-height: 300px;
}

.xxx--img-box-image---max-height-400 {
    max-height: 400px;
}

.xxx--img-box--image---center {
    margin: 0 auto;
}

.xxx--img-box--inner {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    outline: thin solid #fff;
}


/**
 * product
 * @package xxx
 * @component
 */

/*
.xxx--product--sku:before {
    content: '# ';
}
*/

.xxx--product--sku {
    font-size: 10px;
    color: #999;
}


/**
 * elastic-search
 * @package xxx
 * @component
 */

/* NAV */
.xxx--elastic-search-nav {
    margin-bottom: 15px;
}

.xxx--elastic-search-nav--title {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    text-transform: uppercase;
    padding: 7px 0;
    display: inline-block;
}

.xxx--elastic-search-nav--list {
    background: #fff;
}

.xxx--elastic-search-nav--list-item {
    position: relative;
    /*border-bottom: 2px solid var(--blau);*/
}

.xxx--elastic-search-nav--link,
.xxx--elastic-search-nav--link-label,
.xxx--elastic-search-nav--link-count,
.xxx--elastic-search-nav--caret,
.xxx--elastic-search-nav--caret>i {
    transition: background 300ms ease, border 300ms ease, color 300ms ease, padding 300ms ease, margin 300ms ease, top 300ms ease, left 300ms ease, right 300ms ease, bottom 300ms ease;
}

.xxx--elastic-search-nav--link {
    padding: 7px 0;
}

.xxx--elastic-search-nav--link-label {
    /*padding-right: 45px;*/
    font-size: 18px;
    color: #000;
}

.xxx--elastic-search-nav--link:hover .xxx--elastic-search-nav--link-label {
    /*padding-left: 5px;*/
    color: #111;
}

.xxx--elastic-search-nav--link-count {
    /*position: absolute;
    top: 8px;
    right: 25px;*/
    color: #aaa;
    font-size: 12px;
    margin-right: 10px;
}

.xxx--elastic-search-nav--link:hover .xxx--elastic-search-nav--link-count {
    color: #999;
}

.xxx--elastic-search-nav--caret {
    display: flex;
    align-items: center;
}

.xxx--elastic-search-nav--caret>i {
    color: var(--blau);
}

.xxx--elastic-search-nav--caret:hover>i,
.xxx--elastic-search-nav--link:hover+.xxx--elastic-search-nav--caret>i {
    color: #000;
}

/*.xxx--elastic-search-nav--link:hover+.xxx--elastic-search-nav--caret,
.xxx--elastic-search-nav--caret:hover {
    background: #dedede;
}*/

/*
.xxx--elastic-search-nav--caret:hover {
    right: -10px;
    padding-left: 10px;
    padding-right: 10px;
    border-left-color: #999;
}
*/

/*.xxx--elastic-search-nav--link.xxx---is-active .xxx--elastic-search-nav--link-label,
.xxx--elastic-search-nav--link.xxx---is-active .xxx--elastic-search-nav--link-count,
.xxx--elastic-search-nav--caret.xxx---is-active>i {
    color: #2387fa;
}*/

/* SQUARE */
.xxx--elastic-search-nav--square--list-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.xxx--elastic-search-nav--square--link {
    position: relative;
    display: block;
    overflow: hidden;
    border: 2px solid var(--blau);
}

.xxx--elastic-search-nav--square--link-image,
.xxx--elastic-search-nav--square--link-label,
.xxx--elastic-search-nav--square--link-count {
    position: absolute;
}

.xxx--elastic-search-nav--square--link-label,
.xxx--elastic-search-nav--square--link-count {
    padding: 5px 7px;
    z-index: 10;
    background: var(--blau);
    color: #fff;
}

.xxx--elastic-search-nav--square--link-label {
    bottom: 0;
    left: 0;
}

.xxx--elastic-search-nav--square--link-count {
    bottom: 0;
    right: 0;
}

.xxx--elastic-search-nav--square--link-image .xxx--square--inner {
    padding: 0;
}

.square__link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: var(--blau);
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
    color: #fff;
    padding: 0 10px;
    /*opacity: 0.7;*/
}
.square__link span {
    font-size: 15px;
}

.link__image {
    padding: 0;
    width: 100%;
    height: 100%;
}
.link__image-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

[data-object-fit='cover'] {
    object-fit: cover
  }

  @media all and (-ms-high-contrast:none) {
    /* IE11 */
   *::-ms-backdrop,
   .link__image img.link__image-img {
       height: auto;
       position: absolute;
       top: 50%;
       transform: translateY(-50%);

   }
   *::-ms-backdrop,
   .link__image {
       background-color: #e6e6e6;
       border-radius: 4px;
   }
   /* IE11 */
}

/* SUGGESTION */
.xxx--elastic-search-suggestion {
    position: relative;
}

.xxx--elastic-search-suggestion--list-block {
    position: absolute;
    display: block;
    padding: 0;
    z-index: 1000;
    background: #fff;
}

.xxx--elastic-search-suggestion--list-label {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: 12px;
    color: #fff;
    background: #209bd7;
}

.xxx--elastic-search-suggestion--list {
    width: 100%;
    padding: 0;
    background: #fff;
    z-index: 100;
}

.xxx--elastic-search-suggestion--list+.xxx--elastic-search-suggestion--list {
    border-top: 2px solid #209bd7;
}

.xxx--elastic-search-suggestion--list-link {
    padding: 10px 15px;
    font-size: 14px;
    color: #666;
    transition: background 300ms ease, color 300ms ease;
}

.xxx--elastic-search-suggestion--list-item:hover .xxx--elastic-search-suggestion--list-link,
.xxx--elastic-search-suggestion--list-item---selected .xxx--elastic-search-suggestion--list-link {
    font-weight: bold;
    background: #ccc;
    color: #333;
}

.xxx--elastic-search-suggestion--product--list-image-col {
    padding-left: 0;
    padding-right: 0;
    max-width: 100px;
    overflow: hidden;
}

.xxx--elastic-search-suggestion--product--list-image-src {
    position: absolute;
    width: 100%;
}

/* RESULT > CONTROL */
.xxx--elastic-search-result--control--view {
    margin-right: 15px;
}

.xxx--elastic-search-result--control--view.xxx--list .xxx--list-item+.xxx--list-item::before,
.xxx--elastic-search-result--control--limit.xxx--list .xxx--list-item+.xxx--list-item::before {
    content: ' | ';
    color: #ddd;
}

.xxx--elastic-search-result--control--limit.xxx--list .xxx--list-item+.xxx--list-item {
    margin-left: 5px;
}

.xxx--elastic-search-result--control--btn {
    color: #fff;
    background: #999;
    border: thin solid #fff;
    transition: background 300ms ease, color 300ms ease, border 300ms ease;
}

.xxx--elastic-search-result--control--btn,
a.xxx--elastic-search-result--control--btn,
.xxx--elastic-search-result--control--btn span,
a.xxx--elastic-search-result--control--btn span{
    font-size: 14px;
}


.xxx--elastic-search-result--control--btn:hover {
    color: #fff;
    background: #333;
}

.xxx--elastic-search-result--control--btn.xxx---is-active {
    color: #fff !important;
    background: var(--blau);
}

.xxx--elastic-search-result--control--q .xxx--elastic-search-result--control--btn {
    margin-right: 10px;
}

.xxx--elastic-search-result--control--q-amount {
    margin-left: 15px;
}

.xxx--elastic-search-result--control--q-close {
    padding-left: 5px;
}

@media (min-width: 768px) {
    .xxx--elastic-search-result--control--sort-attribute {
        max-width: 50%;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

/* RESULT > FILTER */
.xxx--elastic-search-result--filter {
    margin-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.xxx--elastic-search-result--filter--block {
    position: unset;
}

.filter__div a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter__div a span {
    font-size: 16px;
    color: var(--blau);
}

.xxx--elastic-search-result--filter--block-head {
    margin: 0 0 15px 0;
    color: var(--blau);
    background: #EDEDED;
    /*border-bottom: thin solid #000;*/
    position: relative;
    max-height: 42px;
    overflow: hidden;
}

.xxx--elastic-search-result--filter--block-head:hover {
    overflow: visible;
}

.xxx--elastic-search-result--filter--block-head-title {
    display: block;
    padding: 6px 22px 6px 15px;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    width: 100%;
    line-height: 30px;
    background: #EDEDED;
    word-break: break-word;
}

.xxx--elastic-search-result--filter--block-head-title:hover {
    overflow: visible;
}

.xxx--elastic-search-result--filter--block-head-caret {
    position: absolute;
    top: 10px;
    right: 5px;
    font-size: 22px;
    font-weight: 700;
    color: var(--blau);
}

.filter__items {
    position: relative;
}

.xxx--elastic-search-result--filter--block-head-reset {
    position: absolute;
    top: 8px;
    right: 15px;
    margin: 0 5px;
    padding: 5px;
    font-size: 16px;
    color: var(--blau);
}

.xxx--elastic-search-result--filter--block-body {
    display: none;
    position: absolute;
    top: 42px;
    left: 30px;
    right: 30px;
    background: #fff;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    border: thin solid #EDEDED;
    z-index: 3;
}

.xxx--elastic-search-result--filter--block:nth-of-type(n + 5) .xxx--elastic-search-result--filter--block-body {
    top: 99px;
}

.xxx--elastic-search-result--filter--block:nth-of-type(n + 9) .xxx--elastic-search-result--filter--block-body {
    top: 156px;
}

/*.xxx--elastic-search-result--filter--block-body ul {
    column-count: 3;
    padding-right: 10px;
}*/

.xxx--elastic-search-result--filter--block-body---is-open {
    display: block;
}

/*.xxx--elastic-search-result--filter--block-body---is-absolute {
    position: absolute;
    top: 42px;
    left: 0;
}*/

.xxx--elastic-search-result--filter--block-body .xxx--list {
    margin: 5px 0;
}

.filter__ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2.667%;
}

.xxx--elastic-search-result--filter--option {
    display: block;
    flex: 0 0 23%;
    /*margin: 5px 0;*/
}

.xxx--elastic-search-result--filter--option-label {
    position: relative;
    width: 100%;
    padding-left: 10px;
    display: flex;
    align-items: center;
}


.xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option-value {
    color: #666;
    font-size: 0.8rem;
}

.xxx--elastic-search-result--filter--option-label:hover .xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option-label:hover .xxx--elastic-search-result--filter--option-value,
.xxx--elastic-search-result--filter--option---is-active .xxx--elastic-search-result--filter--option-icon,
.xxx--elastic-search-result--filter--option---is-active .xxx--elastic-search-result--filter--option-value {
    color: #000;
}

.xxx--elastic-search-result--filter--option-count {
    /*position: absolute;*/
    top: 4px;
    right: 15px;
    color: #999;
    font-weight: lighter;
    font-size: 10px;
}

/* RESULT > OBJECT */
/* .xxx--elastic-search-result--object--image-container {
    padding-top: 15px;
    padding-bottom: 15px;
} */

.xxx--elastic-search-result--object *[class*="xxx--product--"][class*="-label"] {
    display: inline-block;
    font-size: 14px;
    color: #333;
}

.xxx--elastic-search-result--object .xxx--product--sku {
    display: block;
    margin-top: 15px;
}

.xxx--elastic-search-result--object .xxx--product--name {
    margin-top: 0;
    margin-bottom: 0;
}

.xxx--elastic-search-result--object .xxx--product--additional {
    margin: 5px 0;
}

.xxx--elastic-search-result--object .xxx--product--additional-label {
    font-size: 10px !important;
}

.xxx--elastic-search-result--object .xxx--product--detail-list {
    margin-bottom: 0;
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item--term,
.xxx--elastic-search-result--object .xxx--product--detail-list-item--data {
    display: inline-block;
    font-size: 10px !important;
}

.xxx--product--detail-list-item--term {
    padding-left: 0;
}

.xxx--product--detail-list-item--data {
    padding-right: 0;
}

.result__kachel .xxx--product--detail-list-item--data {
    padding-left: 0;
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item--term::after {
    content: ': ';
}

.xxx--elastic-search-result--object .xxx--product--detail-list-item {
    margin-bottom: 5px;
}

/* RESULT > BTN */

/* Anatol 20210917 */
.xxx--elastic-search-result--btn-action {
    height: 40px;
    width: 40px;
    /* margin-top: 15px; */
    display: inline-flex;
}

.xxx--elastic-search-result--btn-action i {
    margin: auto;
    font-size: 2em;
}

.xxx--elastic-search-result--btn-action>* {
    color: #fff;
}

/* RESULT > AVAILABILITY */
.xxx--elastic-search-result--availability {
    display: block;
    padding: 7px 15px;
}

.xxx--elastic-search-result--availability-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: thin solid #fff;
    background: grey;
}

.xxx--elastic-search-result--availability-danger .xxx--elastic-search-result--availability-icon {
    background: red;
}

.xxx--elastic-search-result--availability-warning .xxx--elastic-search-result--availability-icon {
    background: yellow;
}

.xxx--elastic-search-result--availability-success .xxx--elastic-search-result--availability-icon {
    background: green;
}

/* RESULT > TABLE */
.xxx--elastic-search-result--table {
    margin-bottom: 0 !important;
}

.xxx--elastic-search-result--table-wrapper {
    width: 100%;
    overflow-x: auto;
}

.xxx--elastic-search-result--table-head {
    background: #ccc;
}

.xxx--elastic-search-result--table-head--cell {
    border-bottom: thin solid #209bd7 !important;
}

.xxx--elastic-search-result--table-body--row {
    background: #fff;
}

.xxx--elastic-search-result--table-body--row:nth-of-type(2n+1) {
    background: #e9e9e9;
}

.xxx--elastic-search-result--table-body--row .xxx--product--detail-list-item--term {
    margin-right: 5px;
}

.xxx--elastic-search-result--table-body--cell-image {
    min-width: 180px;
}

.xxx--elastic-search-result--table-body--cell-image .xxx--product--image {
    margin: 0;
}

.xxx--elastic-search-result--table-body--cell-info .xxx--product--sku {
    margin-top: 0;
    margin-bottom: 10px;
}

.xxx--elastic-search-result--table-body--cell-info .xxx--product--name {
    margin-top: 0;
    margin-bottom: 5px;
}

.xxx--elastic-search-result--table-body--cell-action .xxx--elastic-search-result--btn-action {
    display: block;
}

/* RESULT > LIST */
.xxx--elastic-search-result--list .xxx--list-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    transition: box-shadow 300ms ease;
}

.xxx--elastic-search-result--list .xxx--list-item:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    /* box-shadow: 0px 5px 15px rgb(0 0 0 / 35%); */
}

/* .xxx--elastic-search-result--list .xxx--product--additional {
    margin-bottom: 30px;
} */

.xxx--elastic-search-result--list .xxx--product--additional-label {
    font-size: 12px !important;
}

/* Anatol 20210917 */

/* .xxx--elastic-search-result--list .xxx--product--detail-list {
    margin-bottom: 15px;
} */

/* .xxx--elastic-search-result--list .xxx--product--price {
    margin-bottom: 15px;
} */

.xxx--product--amount-staffel {
    font-size: 12px;
    color: #555;
    min-height: 34px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.xxx--elastic-search-result-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2.65%;
    justify-content: flex-start;
}

.xxx--elastic-search-result--card-object {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 23%;
    max-width: 23%;
    margin-bottom: 20px;
}

.xxx--elastic-search-result-info {
    flex-grow: 1;
}

.xxx--elastic-search-result--list-object {
    display: flex;
    padding: 5px;
    /* align-items: center; */
}

.xxx--elastic-search-result--list-object .xxx--elastic-search-result--list-action {
    align-items: flex-end;
}

.xxx--elastic-search-result--list-object .xxx--elastic-search-result--list-action a {
    margin-top: 5px;
}

.xxx--elastic-search-result--list-object .xxx--elastic-search-result--list-action a:first-child {
    margin-top: 0;
}

/* .xxx--elastic-search-result--card-object:last-child {
    flex: 0 0 31%;
} */

.xxx--elastic-search-result--btn-action {
    padding: 6px 6px;
}

.xxx--elastic-search-result-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0; */
}

.xxx--elastic-search-result-action.xxx--elastic-search-result--list-action {
    flex-direction: column;
}

/* .xxx--elastic-search-result--card-info .xxx--product--name a {
    word-wrap: break-word;
    max-width: 160px;
} */

.result__list .xxx--product--detail-list-item {
    display: flex;
}


/* RESULT > CARD */
/* .xxx--elastic-search-result--card {
    margin: 0 -15px !important;
} */

.xxx--elastic-search-result--card .xxx--elastic-search-result--object {
    /*padding-top: 15px;
    padding-bottom: 15px;*/
    padding: 10px;
    /*box-shadow: 0 0 3px rgb(0 0 0 / 30%);*/
    /*border: 2px solid var(--blau);*/
}

.xxx--elastic-search-result--card .xxx--elastic-search-result--object:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
}


.xxx--elastic-search-result--card-wrapper {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    overflow: hidden;
    transition: box-shadow 300ms ease;
}

.xxx--elastic-search-result--card-wrapper:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}

/* .xxx--elastic-search-result--card-image {
    padding: 0 !important;
    background: #ccc;
} */

.xxx--elastic-search-result--card-image .xxx--product--image {
    margin: 0 !important;
    /* outline: 0 none !important; */
}

.xxx--elastic-search-result--card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 15px; */
    /* border-top: thin solid #aaa; */
}

.xxx--elastic-search-result--card-info .form-group {
    margin-bottom: 0;
}

.xxx--elastic-search-result--card-info .xxx--product--sku {
    margin-top: 0;
}

.xxx--elastic-search-result--card-info .xxx--product--name {
    display: flex;
}

.xxx--elastic-search-result--card-info .xxx--product--name__kachel {
    min-height: 50px;
}

.xxx--elastic-search-result--card-info .xxx--product--name__list {
    margin-top: 5px;
    margin-bottom: 5px;
}

.result__kachel .xxx--elastic-search-result--card-info .xxx--product--additional {
    min-height: 50px;
}

.xxx--elastic-search-result--card-info .xxx--product--price {
    margin-top: 5px;
}

.xxx--product--price__kachel {
    min-height: 40px;
}

.xxx--elastic-search-result--card-action {
    /* border-top: thin solid #aaa; */
    background: #fff;
    min-height: 50px;
}

.list__aktion-preis {
    min-height: 20px;
}

.list__aktion-preis p {
    margin-bottom: 5px;
}

.kachel__bestand {
    min-height: 20px;
}

.kachel__referenz {
    min-height: 18px;
}

@media (max-width: 479px) {
    .xxx--elastic-search-result--card .xxx--elastic-search-result--object {
        width: 100%;
    }
}


/**
 * portal
 * @package xxx
 * @component
 */

.xxx--portal+.xxx--portal {
    margin-top: 30px;
}

.xxx--portal-mod--header {
    padding: 30px 15px;
}

.xxx--portal-mod--headline {
    color: #666;
    font-size: 26px;
}

*[class*="xxx--portal-mod"].headline-centered .xxx--portal-mod--header {
    text-align: center;
}

*[class*="xxx--portal-mod"].headline-centered .xxx--portal-mod--headline {
    color: #c93a3a;
}

.xxx--portal-mod--block {
    position: relative;
    display: block;
    /*padding-top: 15px;
    padding-bottom: 15px;*/
    overflow: hidden;
}

.xxx--portal-mod--block+.xxx--portal-mod--block {
    margin-top: 50px;
}

.xxx--portal-mod--block--header {
    margin-bottom: 15px;
}

.xxx--portal-mod--block--headline {
    margin: 0;
    font-size: 24px;
}

.xxx--portal-mod--block--headline * {
    font-size: 16px;
    color: #c93a3a;
}

.xxx--portal-mod--block--teaser-text a {
    color: #c93a3a;
    text-decoration: underline;
}

.xxx--portal-mod--block-news--article {
    text-align: left;
}

.xxx--portal-mod--block-news--image-link {
    display: block;
    border: thin solid #c93a3a;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .7);
    overflow: hidden;
}

.xxx--portal-mod--block-link {
    position: relative;
    display: block;
    min-height: 60px;
    /*margin: 0 15px;*/
    overflow: hidden;
    border: thin solid #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    transition: box-shadow 300ms ease;
}

.xxx--portal-mod--block-link:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .6);
}

.xxx--portal-mod--block-link--label {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--blau);
    background: rgba(255, 255, 255, .9);
    transition: padding 300ms ease;
}

.link .xxx--portal-mod--block-link--label {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 40px 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background: rgba(62,83,158, .5);
    transition: padding 300ms ease;
}

.xxx--portal-mod--block-link:hover .xxx--portal-mod--block-link--label.xxx---has-src {
    padding: 50px 15px;
}

.xxx--portal-mod--block-link--image-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* XXS */
@media (max-width: 359px) {
    .xxx--portal-mod--block .xxx--list-item {
        width: 100%;
    }
}

/* XS */
@media (max-width: 767px) {
    .xxx--portal-mod--block--header {
        margin-top: 30px;
    }

    .xxx--portal-mod--block--headline,
    .xxx--portal-mod--block--teaser-text {
        text-align: center;
    }

    .xxx--elastic-search-result--card-object {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .xxx--elastic-search-result-action.xxx--elastic-search-result--list-action {
        flex-direction: row;
    }

    .xxx--elastic-search-result--list-object {
        flex-direction: column;
    }

    .xxx--elastic-search-result--list-object .xxx--elastic-search-result--list-action {
        align-items: flex-start;
    }

    .xxx--elastic-search-result--list-object .xxx--elastic-search-result--list-action a {
        margin-top: 0;
    }

    /* .xxx--elastic-search-result-action {
        border-top: thin solid #aaa;
    } */
    .xxx--elastic-search-result--card-info .xxx--product--name a {
        word-wrap: break-word;
        max-width: 100%;
    }
    .xxx--elastic-search-result--card-info .xxx--product--name__kachel {
        min-height: 20px;
    }

    .result__kachel .xxx--elastic-search-result--card-info .xxx--product--additional {
        min-height: 10px;
    }
    .xxx--elastic-search-nav--title {
        font-size: 18px;
    }
}


/**
 * collapse
 * @package xxx
 * @component
 */

.xxx--collapse {
    max-height: 1000px;
    overflow: hidden !important;
    transition: max-height 500ms ease, padding-top 500ms ease, margin-bottom 500ms ease, opacity 500ms ease, border 500ms ease, background 500ms ease, border 500ms ease !important;
}

.xxx--collapse.xxx---is-collapsed {
    max-height: 0 !important;
}

.xxx--collapse.xxx---is-expanded {
    max-height: 3000px;
    overflow: visible !important;
}


/**
 * scroll-sticky
 * @package xxx
 * @component
 */

.xxx--scroll-sticky {
    position: fixed !important;
    top: 0;
    z-index: 1000;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, .5);
}

.xxx--scroll-sticky .navbar-logo {
    display: block !important;
    height: 45px;
}

.xxx--scroll-sticky .xxx--page-header--img {
    display: none;
}


/**
 * parallax
 * @package xxx
 * @component
 */

*[xxx--parallax] {
    position: absolute;
    transition: top 300ms ease;
}


/**
 * pagination
 * @package xxx
 * @component
 */

.xxx--pagination {
    font-family: 'Lato', sans-serif;
}


/**
 * v-card
 * @package xxx
 * @component
 */

.xxx--v-card {
    background: #fff;
    border: thin solid #ccc;
    border-radius: 5px;
}

.xxx--v-card--info {
    margin-top: 15px;
}

.xxx--v-card--image {
    max-width: 100%;
    padding: 15px;
    background: #ccc;
}

*[class*="xxx--v-card--"][class*="-text"],
*[class*="xxx--v-card--"][class*="-address-"] {
    font-size: 12px;
}


/**
 *
 * @package xxx
 * @modifier
 */

.xxx---shadow {
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
}

.xxx---relative {
    position: relative;
}

.xxx---absolute {
    position: absolute;
}

@media (max-width: 767px) {
    .xxx-xs---absolute {
        position: absolute;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---absolute {
        position: absolute;
    }

    .xxx--elastic-search-result-card-list {
        gap: 15px;
    }

    .xxx--elastic-search-result--card-object {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---absolute {
        position: absolute;
    }

    .xxx--elastic-search-result-card-list {
        gap: 3.5%;
    }

    .xxx--elastic-search-result--card-object {
        flex: 0 0 31%;
        max-width: 31%;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---absolute {
        position: absolute;
    }
}

*[class*="xxx---"][class*="---absolute"].xxx---t-0 {
    top: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-1 {
    top: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-2 {
    top: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-3 {
    top: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-4 {
    top: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---t-5 {
    top: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-0 {
    left: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-1 {
    left: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-2 {
    left: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-3 {
    left: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-4 {
    left: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---l-5 {
    left: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-0 {
    right: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-1 {
    right: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-2 {
    right: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-3 {
    right: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-4 {
    right: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---r-5 {
    right: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-0 {
    bottom: 0;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-1 {
    bottom: 5px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-2 {
    bottom: 10px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-3 {
    bottom: 15px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-4 {
    bottom: 30px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-5 {
    bottom: 45px;
}

*[class*="xxx---"][class*="---absolute"].xxx---b-3 {
    bottom: 15px;
}

.xxx---absolute-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.xxx---z-10 {
    z-index: 10;
}

.xxx---z-100 {
    z-index: 100;
}

.xxx---z-1000 {
    z-index: 1000;
}

.xxx---z-10000 {
    z-index: 10000;
}

.xxx---z-100000 {
    z-index: 100000;
}

.xxx---overflow-hidden {
    overflow: hidden;
}

.xxx---block,
.xxx---block-inside>* {
    display: block !important;
}

.xxx---inline,
.xxx---inline-inside>* {
    display: inline-block !important;
}

@media (max-width: 767px) {
    .xxx-xs---inline-inside>* {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---inline-inside>* {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---inline-inside>* {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---inline-inside>* {
        display: inline-block !important;
    }
}

.xxx---vertical-top,
.xxx---inline-inside.xxx---vertical-top>*,
.xxx-xs---inline-inside.xxx---vertical-top>*,
.xxx-sm---inline-inside.xxx---vertical-top>*,
.xxx-md---inline-inside.xxx---vertical-top>*,
.xxx-lg---inline-inside.xxx---vertical-top>* {
    vertical-align: top;
}

.xxx---table-cell {
    display: table-cell !important;
}

.xxx---none {
    display: none !important;
}

@media (max-width: 767px) {
    .xxx-xs---none {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---none {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---none {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---none {
        display: none !important;
    }
}

.xxx---is-open {
    display: block;
}

.xxx---cursor-default {
    cursor: default !important;
}

.xxx---cursor-pointer {
    cursor: pointer !important;
}

.xxx---center {
    margin-top: 50% !important;
    margin-left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.xxx---center-y {
    margin-top: 50% !important;
    transform: translateY(-50%) !important;
}

.xxx---center-x {
    margin-left: 50% !important;
    transform: translateX(-50%) !important;
}

.xxx---square {
    position: relative;
    display: block;
    width: 100%;
}

.xxx---square:after {
    display: block;
    padding-bottom: 100%;
    content: '';
}

.xxx--square--outer {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
}

.xxx--square--inner {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.xxx--square--image {
    max-width: 100%;
    max-height: 100%;
    margin-top: 50%;
    transform: translateY(-50%);
}

.xxx---square-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.xxx---square-background-colored {
    background-color: #ccc;
    outline: thin solid #ccc;
}

.xxx---clear {
    clear: both !important;
}

.xxx---opacity {
    opacity: 1 !important;
}

.xxx---opacity-half,
.xxx---opacity-5 {
    opacity: .5 !important;
}

.xxx---rounded {
    border-radius: 3px;
}

.xxx---w-25 {
    width: 25% !important;
}

.xxx---w-33 {
    width: 33.3% !important;
}

.xxx---w-50 {
    width: 50% !important;
}

.xxx---w-66 {
    width: 66.6% !important;
}

.xxx---w-75 {
    width: 75% !important;
}

.xxx---full-width,
.xxx---w-100 {
    width: 100% !important;
}

@media (max-width: 767px) {
    .xxx-xs---w-25 {
        width: 25% !important;
    }

    .xxx-xs---w-33 {
        width: 33.3% !important;
    }

    .xxx-xs---w-50 {
        width: 50% !important;
    }

    .xxx-xs---w-66 {
        width: 66.6% !important;
    }

    .xxx-xs---w-75 {
        width: 75% !important;
    }

    .xxx-xs---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---w-25 {
        width: 25% !important;
    }

    .xxx-sm---w-33 {
        width: 33.3% !important;
    }

    .xxx-sm---w-50 {
        width: 50% !important;
    }

    .xxx-sm---w-66 {
        width: 66.6% !important;
    }

    .xxx-sm---w-75 {
        width: 75% !important;
    }

    .xxx-sm---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---w-25 {
        width: 25% !important;
    }

    .xxx-md---w-33 {
        width: 33.3% !important;
    }

    .xxx-md---w-50 {
        width: 50% !important;
    }

    .xxx-md---w-66 {
        width: 66.6% !important;
    }

    .xxx-md---w-75 {
        width: 75% !important;
    }

    .xxx-md---w-100 {
        width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---w-25 {
        width: 25% !important;
    }

    .xxx-lg---w-33 {
        width: 33.3% !important;
    }

    .xxx-lg---w-50 {
        width: 50% !important;
    }

    .xxx-lg---w-66 {
        width: 66.6% !important;
    }

    .xxx-lg---w-75 {
        width: 75% !important;
    }

    .xxx-lg---w-100 {
        width: 100% !important;
    }
}

.xxx---no-margin,
.xxx---m-0 {
    margin: 0 !important;
}

.xxx---m-1 {
    margin: 5px !important;
}

.xxx---m-2 {
    margin: 10px !important;
}

.xxx---m-3 {
    margin: 15px !important;
}

.xxx---m-4 {
    margin: 30px !important;
}

.xxx---m-5 {
    margin: 45px !important;
}

.xxx---mt-0 {
    margin-top: 0 !important;
}

.xxx---mt-1 {
    margin-top: 5px !important;
}

.xxx---mt-2 {
    margin-top: 10px !important;
}

.xxx---mt-3 {
    margin-top: 15px !important;
}

.xxx---mt-4 {
    margin-top: 30px !important;
}

.xxx---mt-5 {
    margin-top: 45px !important;
}

.xxx---mb-0 {
    margin-bottom: 0 !important;
}

.xxx---mb-1 {
    margin-bottom: 5px !important;
}

.xxx---mb-2 {
    margin-bottom: 10px !important;
}

.xxx---mb-3 {
    margin-bottom: 15px !important;
}

.xxx---mb-4 {
    margin-bottom: 30px !important;
}

.xxx---mb-5 {
    margin-bottom: 45px !important;
}

.xxx---mtb-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.xxx---mtb-1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.xxx---mtb-2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.xxx---mtb-3 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.xxx---mtb-4 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.xxx---mtb-5 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.xxx---ml-0 {
    margin-left: 0 !important;
}

.xxx---ml-1 {
    margin-left: 5px !important;
}

.xxx---ml-2 {
    margin-left: 10px !important;
}

.xxx---ml-3 {
    margin-left: 15px !important;
}

.xxx---ml-4 {
    margin-left: 30px !important;
}

.xxx---ml-5 {
    margin-left: 45px !important;
}

.xxx---mr-0 {
    margin-right: 0 !important;
}

.xxx---mr-1 {
    margin-right: 5px !important;
}

.xxx---mr-2 {
    margin-right: 10px !important;
}

.xxx---mr-3 {
    margin-right: 15px !important;
}

.xxx---mr-4 {
    margin-right: 30px !important;
}

.xxx---mr-5 {
    margin-right: 45px !important;
}

.xxx---mlr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.xxx---mlr-1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.xxx---mlr-2 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.xxx---mlr-3 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.xxx---mlr-4 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.xxx---mlr-5 {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

@media (max-width: 767px) {
    .xxx-xs---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-xs---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-xs---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .xxx-sm---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-sm---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-sm---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx-md---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-md---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-md---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

@media (min-width: 1200px) {
    .xxx-lg---mt-3 {
        margin-top: 15px !important;
    }

    .xxx-lg---mlr-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .xxx-lg---mlr-3 {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

.xxx---no-padding,
.xxx---p-0 {
    padding: 0 !important;
}

.xxx---p-1 {
    padding: 5px !important;
}

.xxx---p-2 {
    padding: 10px !important;
}

.xxx---p-3 {
    padding: 15px !important;
}

.xxx---p-4 {
    padding: 30px !important;
}

.xxx---p-5 {
    padding: 45px !important;
}

.xxx---pt-zero,
.xxx---pt-0 {
    padding-top: 0 !important;
}

.xxx---pt-1 {
    padding-top: 5px !important;
}

.xxx---pt-2 {
    padding-top: 10px !important;
}

.xxx---pt-3 {
    padding-top: 15px !important;
}

.xxx---pt-4 {
    padding-top: 30px !important;
}

.xxx---pt-5 {
    padding-top: 45px !important;
}

.xxx---pb-zero,
.xxx---pb-0 {
    padding-bottom: 0 !important;
}

.xxx---pb-1 {
    padding-bottom: 5px !important;
}

.xxx---pb-2 {
    padding-bottom: 10px !important;
}

.xxx---pb-3 {
    padding-bottom: 15px !important;
}

.xxx---pb-4 {
    padding-bottom: 30px !important;
}

.xxx---pb-5 {
    padding-bottom: 45px !important;
}

.xxx---ptb-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.xxx---ptb-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.xxx---ptb-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.xxx---ptb-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.xxx---ptb-4 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.xxx---ptb-5 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.xxx---pl-0 {
    padding-left: 0 !important;
}

.xxx---pl-1 {
    padding-left: 5px !important;
}

.xxx---pl-2 {
    padding-left: 10px !important;
}

.xxx---pl-3 {
    padding-left: 15px !important;
}

.xxx---pl-4 {
    padding-left: 30px !important;
}

.xxx---pl-5 {
    padding-left: 45px !important;
}

.xxx---pr-0 {
    padding-right: 0 !important;
}

.xxx---pr-1 {
    padding-right: 5px !important;
}

.xxx---pr-2 {
    padding-right: 10px !important;
}

.xxx---pr-3 {
    padding-right: 15px !important;
}

.xxx---pr-4 {
    padding-right: 30px !important;
}

.xxx---pr-5 {
    padding-right: 45px !important;
}

.xxx---plr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.xxx---plr-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.xxx---plr-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.xxx---plr-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.xxx---plr-4 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.xxx---plr-5 {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.xxx---cookiebot {
    display: flex;
    cursor: pointer;
}

.xxx---cookiebot-videodummy {
    width: 100%;
    height: 40vw;
    max-height: 500px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 50% 50%;
    border: 1px solid #545454;
    filter: blur(5px);
    position: relative;
    opacity: 0.5;
    -ms-filter: alpha(opacity=25);
}

.xxx---cookiebot-playbutton {
    position: absolute;
    bottom: 45%;
    left: 45%;
}

.xxx---cookiebot-playbutton .fa {
    font-size: 6vw;
    margin: auto;
    z-index: 500;
}

.xxx---cookiebot-text {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 20px;
}

.xxx---kst-select {
    margin-top: 10px;
}


#backtop {
    z-index: 10002;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    transition: all .3s ease-out;
    position: fixed;
}

#backtop button {
    color: #209bd7;
    background-color: transparent;
    outline: none;
    border: 5px;
    /* text-shadow: 1px 1px 7px #fff; */

}

#backtop button:hover {
    cursor: pointer;
    background-color: transparent;

}

#backtop button.btn:active {
    box-shadow: none;
}

.fa-fw {
    color: var(--blau);
    margin-bottom: 25px;
    width: 2em;
}

.show {
    opacity: 1 !important
}

.form-group.kostenstelle {
    margin: 5px 0;
}