/* NUTZER */

.nutzer {
    display: flex;
    flex-direction: column;
    /*display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;*/
}

.nutzer__items {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 2%;
    /*display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 280px;
    gap: 15px;*/
}

.nutzer__items .adresse {
    position: relative;
    flex: 0 0 32%;
    max-width: 32%;
    height: 360px;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--blau);
}

.adresse__img {
    width: 100%;
    height: 100%;
}

.adresse__img > div {
    width: 100%;
    height: 100%;
}

.adresse__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.adresse:hover .adresse__info {
    height: 60%;
    padding: 20px 0 15px 0;
}

.adresse__info {
    position: absolute;
    width: 100%;
    height: 28px;
    bottom: 0;
    background: rgba(255, 255, 255, 0.85);
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition-property: height;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    overflow: hidden;
}

.adresse__info p,
.adresse__info a {
    margin: 0;
    color: var(--blau);
    text-align: center;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .nutzer__items {
        gap: unset;
    }

    .nutzer__items .adresse {
        flex: 0 0 100%;
        max-width: 100%;
        height: 360px;
    }
    .adresse__info {
        height: 18px;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .nutzer__items {
        gap: 10px 2%;
    }

    .nutzer__items .adresse {
        flex: 0 0 48%;
        max-width: 48%;
        height: 360px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}