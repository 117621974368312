/* MAIN */


ul.dropdown-menu {
    display: none;
    padding: 10px;
    min-width: 200px;
}

.menu__main:hover ul.dropdown-menu {
    display: block;
}

ul.dropdown-menu > li > div > a {
    font-weight: 600;
    /*font-size: 28px;
    line-height: 34px;*/
    font-size: 20px !important;
    line-height: 28px;
    color: var(--blau);
    text-transform: unset;
}

/*===================== Service-Box ==============================*/

.service-box {
    position: fixed;
    right: 0;
    top: 60%;
    transform: translateY(-60%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.service-box__item .svg-icon {
    fill: #fff;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.service-box__item i {
    color: #fff;
    font-size: 34px;
    margin-right: 10px;
}


.service-box__item {
    display: flex;
    align-items: center;
    justify-content: center;
    /*padding: 16px;*/
    background-color: var(--blau);
    border: 1px solid #fff;
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
    padding: 0 10px;
    overflow: hidden;
    transition: width 800ms, background-color 400ms;
}

.service-box__item span {
    display: none;
}

.service-box__item:hover {
    background-color: #fff;
    border: 1px solid var(--blau);
    width: 220px;
}

.service-box__item:hover .svg-icon {
    fill: var(--blau);
}

.service-box__item:hover i {
    color: var(--blau);
}


.service-box__item:hover span {
    display: block;
}

/*=====================/ Service-Box ==============================*/

.navbar__bottom .navbar-icon a i {
    color: #fff;
}

.navbar__bottom .navbar-icon .svg-icon__menu {
    height: 30px;
    width: 30px;
    fill: #fff;
}

.sidemenu__xs {
    display: flex;
    height: 100%;
    position: fixed;
    left: 0;
    z-index: 20;
    box-shadow: 5px 0px 15px 1px #aaaaaa;
}

.side__main {
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%;
    /*width: 250px;*/
    width: auto;
    z-index: 20;
    background-color: #fff;
    padding-top: 20px;
    /*opacity: 0.95;*/
}

.side__main ul.dropdown-menu > li > div > a {
    font-weight: 400;
    font-size: 1.1rem !important;
    line-height: 24px;
    color: #333;
    text-transform: unset;
}

.side__main a {
    font-size: 1.2rem !important;
}



.side__main ul.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
}


.side__header {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 60px;
    z-index: 20;
    background-color: var(--blau);
}

.side__header .navbar-toggle .icon-bar {
    width: 35px;
    height: 4px;
    margin-bottom: 5px;
    background-color: #fff;
}

.side__header .navbar-header {
    display: flex;
    flex-direction: column;
}

.side__header .navbar__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.navbar-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;
}

.side__header .navbar-icon a i {
    padding: 0;
}

.side__header-logo {
    background-color: #fff;
    padding: 10px 5px;
}

/*===================== Hamburger Button ==============================*/

#menu__toggle {
    opacity: 0;
}

.menu__btn {
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 14px;
    width: 32px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #fff;
}

.menu__btn > span::before {
    content: '';
    top: -8px;
}

.menu__btn > span::after {
    content: '';
    top: 8px;
}

#menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
}

#menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
}

#menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}

/*=====================/ Hamburger Button ==============================*/


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .navbar {
        min-height: unset;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .navbar {
        min-height: unset;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}
