@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Bold.woff2") format("woff2"), url("../Lato-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato-Regular.woff2") format("woff2"), url("../Lato-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
