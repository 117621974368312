/* FOOTER */

.xxx--page-footer .svg-icon {
    height: 32px;
    width: 32px;
    fill: #fff;
}

.footer__oben {
    background-color: #000;
    border-bottom: 16px solid var(--blau);
    padding-top: 40px;
    padding-bottom: 36px;
}

.footer__oben .container {
    display: flex;
}

.navbar-default ul.navbar-nav.footer__menu {
    margin-left: 0;
    display: flex;
    flex-direction: column;
}

.footer__menu li.li__konto a {
    font-weight: 700;
    font-size: 20px;
    line-height: 10px;
    color: #fff;
    text-transform: uppercase;
    padding-left: 0;
}

.footer__oben-item {
    flex: 0 0 24%;
}

.footer__oben-item.social__icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__oben-item.lausitz__link img {
    max-width: 150px;
}

.footer__social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer__social a:hover .svg-icon {
    fill: var(--blau);
}

.footer__mitte {
    background-color: #ededed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 20px;
}

.footer__mitte p {
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
}

.footer__mitte ul.footer__mitte-ul {
    display: flex;
    padding: 0;
}

.footer__mitte ul.footer__mitte-ul li {
    list-style: none;
    margin-right: 30px;
}

.footer__unten {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 120px;
}

.footer__unten ul.footer__bottom-ul li {
    margin-right: 30px;
}


.footer__mitte ul.footer__mitte-ul li a {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
}

.footer__mitte ul.footer__mitte-ul li a:hover {
    color: var(--blau);
}

.footer__unten ul.footer__bottom-ul li a {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
}

.footer__unten ul.footer__bottom-ul li a:hover {
    color: var(--blau);
}


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .footer__oben .container {
        flex-direction: column;
    }

    .footer__mitte {
        padding-top: 17px;
        padding-bottom: 20px;
    }
    .footer__mitte p {
        font-size: 24px;
        line-height: 10px;
    }
    .footer__mitte-ul,
    .footer__bottom-ul {
        display: flex;
        flex-direction: column;
    }
    .footer__unten {
        padding-left: 0;
    }
    .footer__unten ul.footer__bottom-ul li a {
        font-size: 16px;
        line-height: 10px;
    }
    .footer__oben,
    .footer__unten {
        text-align: center;
    }
    .footer__social {
        justify-content: center;
        margin-top: 20px;
    }
    .footer__mitte ul.footer__mitte-ul {
        text-align: center;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .footer__menu li.li__konto a {
        font-size: 20px;
        line-height: 4px;
    }
    .xxx--page-footer .svg-icon {
        height: 40px;
        width: 40px;
    }
    .footer__mitte {
        padding-top: 40px;
        padding-bottom: 28px;
    }
    .footer__mitte p {
        font-size: 24px;
        line-height: 10px;
    }
    .footer__mitte ul.footer__mitte-ul li a {
        font-size: 18px;
        line-height: 10px;
    }
    .footer__unten ul.footer__bottom-ul li a {
        font-size: 16px;
        line-height: 16px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}