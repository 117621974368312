/* DOKUMENT */

.doklist__hk {
    display: flex;
    gap: 20px 2%;
    flex-wrap: wrap;
}

.doklist__uk {
    flex: 0 0 32%;
    max-width: 32%;
}

.doklist__uk a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}