/* KACHEL */


.xxx--elastic-search-result--object--image-container {
    height: 130px;
    max-height: 130px;
    text-align: center;
}

.xxx--elastic-search-result--object--image-container img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto;
}

.xxx--elastic-search-result--card-object .xxx--product--name__kachel {
    min-height: 50px;
}

.xxx--product--price,
.xxx--elastic-search-result-info__top {
    text-align: center;
}

.xxx--elastic-search-result-info__top a.xxx--product--sku-label {
    font-size: 12px !important;
}

.liste__bestand {
    min-height: 28px;
}

/*===================== Menge ==============================*/

.xxx--product--amount .input-group {
    background-color: var(--blau);
    border-radius: 8px;
    display: flex;
    align-items: baseline;
    padding: 5px 10px;
}

.xxx--product--amount .input-group span {
    color: #fff;
}

.xxx--product--amount .input-group input.form-control {
    border-radius: 8px !important;
    padding: 4px 12px;
    height: unset;
    text-align: center;
}

.xxx--product--amount button {
    background-color: transparent;
    border: none;
}

.xxx--product--amount button i {
    font-size: 12px;
    color: #fff;
}

/*=====================/ Menge ==============================*/

/*===================== Buttons ==============================*/
.xxx--elastic-search-result--btn {
    width: 100%;
}

.xxx--elastic-search-result--btn span {
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    width: 100%;
}


/*=====================/ Buttons ==============================*/
