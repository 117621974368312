/* FILTER */


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .xxx--elastic-search-result--filter--block-body {
        width: calc(100% - 30px);
        min-width: unset;
    }
    .xxx--elastic-search-result--filter--block-body ul {
        column-count: 1;
    }
    .xxx--elastic-search-result--filter--block {
        position: relative;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .filter__ul {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
    }

    .xxx--elastic-search-result--filter--option {
        display: block;
        flex: 0 0 31%;
        /*margin: 5px 0;*/
    }

    .xxx--elastic-search-result--filter--block:nth-of-type(n + 3) .xxx--elastic-search-result--filter--block-body {
        top: 99px;
    }
    .xxx--elastic-search-result--filter--block:nth-of-type(n + 5) .xxx--elastic-search-result--filter--block-body {
        top: 156px;
    }
    .xxx--elastic-search-result--filter--block:nth-of-type(n + 7) .xxx--elastic-search-result--filter--block-body {
        top: 213px;
    }
    .xxx--elastic-search-result--filter--block:nth-of-type(n + 9) .xxx--elastic-search-result--filter--block-body {
        top: 269px;
    }
    .xxx--elastic-search-result--filter--block:nth-of-type(n + 11) .xxx--elastic-search-result--filter--block-body {
        top: 326px;
    }
    .xxx--elastic-search-result--filter--block:nth-of-type(n + 13) .xxx--elastic-search-result--filter--block-body {
        top: 363px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}