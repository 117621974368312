/* WARENKORB */

.warenkorb {
    margin-top: 40px;
}

.wk_footer a {
    color: #fff;
}

.header__menu li.wk__header a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    position: relative;
}

.wk__header a .svg-icon__menu {
    height: 30px;
    width: 30px;
}

.wk-anzahl {
    position: absolute;
    top: 7px;
    left: 25px;
    background-color: var(--blau);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: #fff;
    font-size: 8px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}