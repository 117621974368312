/* KNT */

.knt__item-content.nur__content {
    flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
}

.knt__item-content img {
    width: unset;
}

