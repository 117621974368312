/* HEADER */

.xxx--page-header {
    background-color: #000;
}

.xxx--page-header--head {
    border-bottom: 4px solid var(--blau);
}

.xxx--page-header--menu {
    padding-top: 10px;
    padding-bottom: 10px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

ul.header__menu {
    display: flex;
    align-items: center;
}

.xxx--elastic-search-suggestion.input-group {
    background-color: var(--blau);
    border: 1px solid var(--blau);
    border-radius: 8px;
}

.xxx--elastic-search-suggestion.input-group label {
    margin-bottom: 0;
}


.nav.navbar-nav.header__menu li a {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex !important;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

/*.header__menu .li__konto {
    background-color: var(--blau);
    border-radius: 8px;
}*/

.header__menu li.blau {
    background-color: var(--blau);
    border-radius: 8px;
}

.header__menu li.li__konto a > span {
    display: flex;
    align-items: center;
}


.nav.navbar-nav.header__menu li a:hover,
.nav.navbar-nav.header__menu li a:focus {
    background-color: transparent;
}

.header__menu .svg-icon__menu {
    height: 24px;
    width: 24px;
    fill: #fff;
    margin-right: 15px;
}

.header label {
    background-color: var(--blau);
    margin-bottom: 0;
    border: 1px solid var(--blau);
    border-radius: 8px;
}

.header__suchen input.form-control {
    background-color: var(--blau);
    height: unset;
    padding: 3px 12px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    border-radius: 8px;
    border: 1px solid var(--blau);
}

.header-suchen__icon input.form-control {
    background-color: var(--blau);
    height: unset;
    padding: 3px 12px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    border-radius: 8px;
    border: 1px solid var(--blau);
}


.header__suchen span.input-group-addon {
    background-color: #000;
    border: 1px solid var(--blau);
    border-radius: 8px !important;
    color: #fff;
}

.header__suchen span.input-group-addon .svg-icon {
    height: 20px;
    width: 20px;
    fill: #fff;
}

.header-suchen__icon {
    display: flex;
    background-color: var(--blau);
    height: 38px;
}

.header-suchen__icon span.input-group-addon {
    background-color: #000;
    border: 1px solid var(--blau);
    border-radius: 8px !important;
    color: #fff;
    width: 37px;
}

.header__login .panel-body {
    padding: 0 10px;
}

.header__login .panel-default > .panel-heading {
    background-color: var(--blau);
    border-color: var(--blau);
    padding: 5px 10px;
}

.header__login h4 a {
    color: #fff;
    font-size: 20px;
}

.header__menu .header__info {
    display: flex !important;
    align-items: center;
    padding: 5px 20px;
    position: relative;
    border-radius: 8px 8px 0 0;
}

.header__menu .header__info.blau {
    display: flex !important;
    align-items: center;
    padding: 5px 20px;
    position: relative;
    border: 1px solid var(--blau);
    border-radius: 8px 8px 0 0 !important;
}

.header__info p {
    font-size: 18px;
    color: #fff;
}


.header__menu .header__info .info__konto {
    position: absolute;
    top: 47px;
    left: 0;
    display: none;
    background-color: #fff;
    width: 100%;
    z-index: 2;
    padding: 10px;
    border: 1px solid #c4c4c4;
}

.header__info:hover .info__konto {
    display: flex;
    flex-direction: column;
}

.nav.header__menu li.header__info .info__konto a {
    font-size: 16px;
    font-weight: 400;
    /*color: var(--blau);*/
    /*padding-top: 0;*/
    /*padding-bottom: 0;*/
}

.nav.header__menu li.header__info .info__konto a:hover {
    color: var(--blau) !important;
}

.info__konto button.btn {
    /*border-top: 1px solid var(--blau);*/
    color: var(--blau);
    font-size: 16px;
    border-radius: 0;
    margin-top: 10px;
}

.collapse-menu.in ul.nav {
    background-color: var(--blau);
}

.collapse-menu.in ul.nav li {
    border-bottom: 1px solid #fff;
}

.collapse-menu.in ul.nav li a {
    color: #fff;
}

.navbar-collapse {
    padding-right: 0;
    padding-left: 0;
}

.xxx--elastic-search-suggestion--list-block {
    width: 600px;
}

.wk-angebot {
    font-size: 30px;
}

.xxx--elastic-search-suggestion label {
    background-color: transparent;
    border: none;
}

/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .xxx--page-header--menu {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-nav {
        margin: 0;
    }


    /*a:link, a:visited, a:hover, a:active, a:focus {
        color: #fff;
    }*/
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .nav.navbar-nav.header__menu li a {
        font-size: 18px;
    }

    .header__menu .svg-icon__menu {
        height: 28px;
        width: 28px;
    }

    .header__suchen input.form-control {
        font-size: 14px;
    }

    .header__suchen span.input-group-addon .svg-icon {
        height: 18px;
        width: 18px;
    }

    .header__menu .header__info .info__konto {
        top: 46px;
    }

    .nav.header__menu li.header__info .info__konto a {
        font-size: 16px;
    }

    .navbar-default .navbar-nav > li > a {
        font-size: 16px;
    }

    .xxx--page-header--menu {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .xxx--elastic-search-suggestion--list-block {
        width: 500px;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {
    .header__info {
        min-width: 200px;
    }
}