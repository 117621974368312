/* DETAIL */

/*.aside__mitarbeiter {
    !*display: flex;
    align-items: flex-end;
    justify-content: flex-end;*!
    !*position: fixed;
    right: 0;
    top: 30%;
    transform: translateY(-30%);*!
}*/

.aside__mitarbeiter ul {
    display: flex;
}

.button__return {
    display: flex;
    align-items: flex-start;
}

.artikeldetail_option p {
    font-size: 1.2rem;
    /*font-weight: 700;*/
    font-weight: 400;
}

.artikeldetail_hauptbild_container:hover {
    background-color: var(--blau);
}

.artikeldetail_option p.preis__bemerkung {
    font-size: 0.8rem;
    font-weight: 700;
}

.artikeldetail_option .artikeldetail_tabset p {
    font-size: 1rem;
    font-weight: 400;
}

.artikeldetail_hauptbild_container {
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.artikeldetail_hauptbild_container .hauptimg {
    max-height: 100%;
}

.artikeldetail_info {
    margin-bottom: 30px;
}

.artikeldetail_info h2 {
    margin: 0;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.8rem;
}

.artikeldetail_tabset {
    border: 2px solid var(--blau);
    border-radius: 16px;
    padding: 5px 20px;
}

.detail__mengen .detail-menge__input {
    background-color: var(--blau);
    border-radius: 8px;
    border: 3px solid var(--blau);
    /*display: flex;
    align-items: baseline;
    padding: 5px 10px;*/
}

.artikeldetail_button {
    /*background-color: var(--blau);*/
    border-radius: 8px;
    display: flex;
    align-items: baseline;
}

.artikeldetail_button button span {
    font-size: 0.8rem;
    text-transform: uppercase;
}

.artikeldetail_button .form-group.input-group {
    background-color: var(--blau);
    border-radius: 8px;
    padding: 3px 12px;
}

.artikeldetail_button .form-group.input-group select{
    background-color: var(--blau);
    border-radius: 8px 0 0 8px;
    padding: 4px 12px;
    border: none;
    color: #fff;
}

.artikeldetail_button .form-control:focus {
    box-shadow: none;
}

.artikeldetail_button .form-group {
    margin-bottom: 0;
}

.artikeldetail_button .input-group-btn button {
    background-color: var(--blau);
    border: none;
    border-left: 2px solid #fff;
    color: #fff;
}

.detail__mengen button {
    background-color: transparent;
    border: none;
}

.detail__mengen .input-group input.form-control {
    border-radius: 8px !important;
    padding: 6px 12px;
    height: unset;
    text-align: center;
}

.detail__mengen .input-group-addon {
    border: none;
}

.detail__mengen  .input-group span {
    color: #fff;
}

.detail__mengen i {
    font-size: 20px;
    color: #fff;
}

.detail-menge__input .dropdown-menu {
    border: 1px solid var(--blau);
    padding: 0;
}

.detail-menge__input .dropdown-menu span {
    color: #000;
}

.detail__preis {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.detail__preis .artikeldetail_preis,
.artikeldetail_preis.artikelpreis__anfrage {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 15px;
}

.detail__preis .artikeldetail_preis p {
    margin: 0;
}

.bestand__input-group {
    border: 1px solid var(--blau);
    border-radius: 8px;
}

.bestand__input-group .input-group-addon {
    border: none;
}

.artikeldetail_variantenauswahl .input-group {
    background-color: var(--blau);
    border: 1px solid var(--blau);
    border-radius: 8px;
}

.artikeldetail_variantenauswahl .input-group select.form-control {
    border: none;
    border-radius: 0 8px 8px 0;
}

.artikeldetail_variantenauswahl .input-group .input-group-addon {
    border: none;
    border-radius: 8px;
    color: #fff;
    min-width: 120px;
    padding: 6px;
}

/*.table__staffel {
    margin-bottom: 0;
}*/

.table__staffel>thead {
    background-color: var(--blau);
    color: #fff;
}

.artikeldetail_accordion .panel-title a {
    color: var(--blau);
}


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .artikeldetail_option p {
        font-size: 1rem;
    }
	.artikeldetail_button {
        flex-direction: column;
    }
    .artikeldetail_button > div {
        margin-bottom: 5px;
    }
    .artikeldetail_tabset>.nav-tabs {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--blau);
        padding-bottom: 1px;
    }
    .artikeldetail_tabset>.nav-tabs>li.active>a {
        color: var(--blau);
        font-weight: 700;
        border-bottom: none;
    }
    .detail__preis {
        align-items: flex-start;
    }
    .detail__preis .artikeldetail_preis,
    .artikeldetail_preis.artikelpreis__anfrage {
        justify-content: flex-start;
    }
    .artikeldetail_accordion .panel-body {
        max-height: 200px;
        overflow: auto;
    }

}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {

}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}