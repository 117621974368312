/* ACCORDION */

.accordion__li,
.xxx--elastic-search-nav--title {
    border-bottom: 2px solid var(--blau);
}

.accordion__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    .xxx--elastic-search-nav--link-label {
        font-size: 12px;
    }
    p.produkte_button {
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .xxx--elastic-search-nav--link-label {
        font-size: 12px;
    }
}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .xxx--elastic-search-nav--link-label {
        font-size: 12px;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}