/* PORTAL */

.bg-grau {
    background-color: #f5f6fa;
}

.bg-blau {
    background-color: var(--blau);
}

a.titel-sm {
    display: none;
}

a.titel-d {
    display: block;
}


.titel-border::after {
    content: "";
    display: block;
    height: 11px;
    width: 180px;
    background-image: url(../images/border20012.png);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: left bottom;
}

.bg-blau h2 {
    color: #fff;
}

.bg-blau .titel-border::after {
    content: "";
    display: block;
    height: 11px;
    width: 180px;
    background-image: url(../images/border20012_weiss.png);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: left bottom;
}


.gal__imgtext {
    position: absolute;
    top: 15%;
    left: 0;
    width: 60%;
}

.gal__imgtext-h2 {
    margin-bottom: 40px;
}

.gal__imgtext h2 {
    color: #fff;
    font-size: 55px;
    line-height: 66px;
    margin-left: 100px;
}

.gal__imgtext p {
    color: #fff;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    margin-left: 100px;
}

/*=====================Katalog Service==============================*/

.service .xxx--portal-mod--block {
    padding-top: 0;
    padding-bottom: 0;
}

.service .xxx--portal-mod--block div.row{
    display: flex;
}

/*.service h2 {
    font-size: 44px;
    line-height: 32px;
    text-transform: uppercase;
}*/

/*.service h3 {
    font-size: 32px;
    line-height: 40px;
}*/

/*.service p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
}*/

.service .xxx--portal-mod--block+.xxx--portal-mod--block {
    margin-top: 0;
}

.knt__item {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.knt__item img {
    width: 100%;
}

.service-link .knt__item img {
    height: 260px;
    object-fit: cover;
}

.knt__item-content {
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.knt__item-content a {
    color: var(--blau);
    /*font-weight: 600;*/
}

.xxx--portal-mod--block-knt--teaser-btn {
    align-self: flex-end;
}

.knt__item-img,
.knt__item-content {
    flex: 0 0 50%;
}

.xxx--portal-mod--block-knt:nth-of-type(odd) .knt__item-img,
.xxx--portal-mod--block-knt:nth-of-type(even) .knt__item-content {
    order: 2;
}

.xxx--portal-mod--block-knt:nth-of-type(even) .knt__item-img,
.xxx--portal-mod--block-knt:nth-of-type(odd) .knt__item-content {
    order: 1;
}

/*=====================/Katalog Service==============================*/

/*===================== Service LINK ==============================*/

.service .titel-border {
    margin-top: 50px;
    margin-bottom: 50px;
}

.service-link,
.news {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.service-link::before,
.service-link::after,
.news::before,
.news::after{
    content: none;
}

.service-link .xxx--portal-mod--block-knt {
    flex: 1 0 23%;
}

.service-link .knt__item-content,
.news .knt__item-content {
    display: none;
}

.service-link .knt__item-img,
.news .knt__item-img{
    flex: 0 0 100%;
    position: relative;
}

.service-link .galbild_vorsch1 {
    height: 200px;
}

.service-link .galbild_vorsch1  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.service-link .knt__item-img .galerie_caption {
    background: rgba(62,83,158, .5);
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-link .knt__item-img .galerie_caption span {
    color: #fff;
}

.service-link .xxx--portal-mod--block+.xxx--portal-mod--block,
.news .xxx--portal-mod--block+.xxx--portal-mod--block {
    margin-top: 0;
}


/*=====================/ Service LINK ==============================*/

/*===================== NEWS ==============================*/

.leistungen .news {
    gap: 20px 2.5%;
}

.leistungen .knt__item img {
    height: 230px;
    object-fit: cover;
}

.news .xxx--portal-mod--block-knt {
    flex: 0 0 31%;
    border: 2px solid var(--blau);
}

.leistungen .news .xxx--portal-mod--block-knt {
    flex: 0 0 23%;
    border: 2px solid var(--blau);
}

.news .knt__item-img .galerie_caption {
    width: 100%;
    min-height: 80px;
    padding: 20px 10px;
}

.news .knt__item-img .galerie_caption span {
    font-weight: 600;
}

.news-angebote .knt__item-img,
.news-angebote .knt__item-content {
    flex: 1 0 100%;
}

.news-angebote .knt__item-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
    justify-content: space-around;
    align-items: flex-end;
    text-align: right;
    padding: 30px;
}

.news-angebote .galerie_caption {
    display: none;
}

.news-angebote p,
.news-angebote h2,
.news-angebote h3 {
    color: #fff;
    font-weight: 600;
}

/*=====================/ NEWS ==============================*/

.link .clearfix__div::before,
.link .clearfix__div::after {
    content: none;
}

.link .clearfix__div {
    display: flex;
    gap:  2%;
    /*justify-content: space-between;*/
}

.link .clearfix__div>div {
    flex: 0 0 32%;
    /*max-width: 380px;
    max-height: 380px;
    margin: 0;*/
}

.slider__items {
    height: 100%;
    display: flex;
}

.slider__items>div {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    width: 100%;
    border: 2px solid var(--blau);
}

.artikelslider .carousel-inner .carousel-inner {
    display: flex;
}

.artikelslider .xxx--product--name__kachel {
    height: 40px;
}

.xxx--product--name__kachel a.xxx--product--name-label {
    display: flex;
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 38px;
    width: 100%;
}

/*===================== OXOMI Portal ==============================*/

.oxomi-portal {
    padding-left: 0;
    padding-right: 0;
}

.oxomi-portal .clearfix__div {
    display: flex;
    gap: 1.3%;
}

.oxomi-portal .clearfix__div>div {
    flex: 0 0 24%;
    max-width: 24%;
}




/*=====================/ OXOMI Portal ==============================*/


/*===================== TimeLine ==============================*/

.timeline__knt {
    display: flex;
}

.timeline__knt .item__line,
.timeline__knt .item__time {
    flex: 0 0 50%;
    padding-top: 30px;
}

.item__time a span {
    font-size: 2.8rem;
    font-weight: 700;
    color: var(--blau);
    /*border: 1px solid var(--blau);
    border-radius: 50%;
    padding-left: 30px;
    padding-right: 30px;*/
}

.timeline__knt:nth-child(even) .item__line {
    display: flex;
    flex-direction: column;
}

.timeline__knt:nth-child(odd) .item__line {
    border-right: 5px solid var(--blau);
    order: 1;
    padding-right: 2.8rem;
}

.timeline__knt:nth-child(even) .item__line {
    border-left: 5px solid var(--blau);
    order: 2;
    margin-left: -5px;
    padding-left: 2.8rem;
}


.timeline__knt:nth-child(odd) .item__time {
    order: 2;
    padding-left: 2.8rem;
}

.timeline__knt:nth-child(even) .item__time  {
    order: 1;
    text-align: right;
    padding-right: 2.8rem;
}

/*=====================/ TimeLine ==============================*/


/*
*
* Extra Small (XS)
*/

@media (max-width: 767px) {
    a.titel-sm {
        display: block;
    }

    a.titel-d {
        display: none;
    }
    .titel-border::after {
        height: 6px;
        width: 120px;
        background-size: 80%;
    }
    .service .titel-border {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    /*.service h2 {
        font-size: 22px;
        line-height: 20px;
    }*/
    /*.service h3 {
        font-size: 18px;
        line-height: 24px;
    }*/
    /*.service p {
        font-size: 14px;
        line-height: 18px;
    }*/

    .knt__item-img,
    .knt__item-content {
        flex: 0 0 100%;
    }
    .xxx--portal-mod--block-knt:nth-of-type(odd) .knt__item-img,
    .xxx--portal-mod--block-knt:nth-of-type(even) .knt__item-img {
        order: 1;
    }

    .xxx--portal-mod--block-knt:nth-of-type(even) .knt__item-content,
    .xxx--portal-mod--block-knt:nth-of-type(odd) .knt__item-content {
        order: 2;
    }
    .service-link .xxx--portal-mod--block-knt,
    .news .xxx--portal-mod--block-knt{
        flex: 0 0 100%;
    }
    .news-angebote p.teasertext {
        display: none;
    }
    .news .galerie_caption {
        display: none;
    }
    .news-angebote a.titel-sm {
        display: none;
    }

    .news-angebote a.titel-d {
        display: block;
    }
    .service-link .knt__item img {
        height: 200px;
        object-fit: cover;
    }
    .leistungen .news .xxx--portal-mod--block-knt {
        flex: 0 0 100%;
    }
    .oxomi-portal .clearfix__div {
        flex-wrap: wrap;
    }

    .oxomi-portal .clearfix__div>div {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .timeline__knt {
        flex-direction: column;
    }

    .timeline__knt .item__line,
    .timeline__knt .item__time {
        flex: 0 0 100%;
    }
    .timeline__knt:nth-child(odd) .item__line {
        order: 2;
    }

    .timeline__knt:nth-child(odd) .item__time {
        order: 1;
    }
    .link .clearfix__div {
        flex-direction: column;
        gap: 15px;
    }

    .service .xxx--portal-mod--block div.row {
        flex-direction: column;
    }
}


/*
*
* Small (SM)
*/

@media (min-width: 768px) and (max-width: 991px) {
    .service .titel-border {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    /*.service h2 {
        font-size: 30px;
        line-height: 24px;
    }*/
    /*.service h3 {
        font-size: 19px;
        line-height: 33px;
    }*/
    /*.service p,
    .news-angebote p {
        font-size: 1rem;
        line-height: 20px;
    }*/
    .service-link {
        gap: 20px;
    }
    .service-link .knt__item-img .galerie_caption {
        min-height: 60px;
    }
    .service-link .xxx--portal-mod--block-knt {
        flex: 0 0 31%;
    }
    .service-link .knt__item img {
        height: 200px;
        object-fit: cover;
    }
    .leistungen .news {
        gap: 20px 2.5%;
    }
    .leistungen .news .xxx--portal-mod--block-knt {
        flex: 0 0 31%;
    }

}


/*
*
* Medium (MD)
*/

@media (min-width: 992px) and (max-width: 1199px) {
    .service .titel-border {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    /*.service h2 {
        font-size: 36px;
        line-height: 32px;
    }*/
    /*.service h3 {
        font-size: 24px;
        line-height: 37px;
    }*/
    /*.service p {
        font-size: 1rem;
        line-height: 21px;
    }*/
    .service-link {
        gap: 25px;
    }
    .service-link .knt__item-img .galerie_caption {
        min-height: 60px;
    }
    .gal__imgtext h2 {
        font-size: 36px;
        line-height: 30px;
    }
    .gal__imgtext p {
        font-size: 14px;
        line-height: 19px;
    }
    .service-link .knt__item img {
        height: 200px;
        object-fit: cover;
    }
}


/*
*
* Large (LG)
*/

@media (min-width: 1200px) {

}